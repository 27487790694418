import { FC, useMemo } from "react"

import {
	CustomerDetail,
	makeApiErrorMessage,
	UpdateCustomerPatch,
	useUpdateCustomer,
} from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"
import {
	Box,
	GridContainer,
	GridItem,
	Heading,
	HeadingDivider,
	Icon,
	Label,
	useToast,
} from "@ncs/web-legos"

import { sectionContentsContainer, sectionHeadingProps } from "../../customer-detail-util"
import { DetailsTabAbout, DetailsTabFinance } from "./components"

export interface CustomerDetailsTabProps {
	customer: CustomerDetail
}

export const CustomerDetailsTab: FC<CustomerDetailsTabProps> = ({ customer }) => {
	const { makeErrorToast, makeSuccessToast } = useToast()

	const updateCustomer = useUpdateCustomer()

	// Backend expects the update call to always have some of the fields, so I'm just
	// dumping them all in.
	const initialValues: UpdateCustomerPatch = useMemo(() => {
		return {
			address1: customer.address1,
			address2: customer.address2,
			chemicalDiscountPercent: extractNumber(customer.chemicalDiscountPercent),
			city: customer.city,
			contactName: customer.contactName,
			customerNumber: customer.customerNumber,
			detailDiscountPercent: extractNumber(customer.detailDiscountPercent),
			email: customer.email,
			fuelSurchargeBillable: customer.fuelSurchargeBillable,
			isFstActive: customer.isFstActive,
			name: customer.name,
			partDiscountPercent: extractNumber(customer.partDiscountPercent),
			phone: customer.phone,
			postalcode: customer.postalcode,
			rdcId: customer.rdc?.id ?? null,
			siteNumber: customer.siteNumber,
			state: customer.state,
			useAlternateLabor: customer.useAlternateLabor,
			organizationId: customer.organization?.id ?? null,
			customerClassId: customer.customerClass?.id ?? null,
			customerTypeId: customer.customerType?.id ?? null,
			customerSubclassId: customer.customerSubclass?.id ?? null,
			freightClassId: customer.freightClassId ?? null,
			chemicalSurchargeBillable: customer.chemicalSurchargeBillable ?? false,
			poRequired: customer.poRequired ?? false,
		}
	}, [customer])

	const handleUpdateCustomer = async (updates: Partial<UpdateCustomerPatch>) => {
		try {
			await updateCustomer({
				updates: {
					...initialValues,
					...updates,
				},
				id: customer.id,
			})
			makeSuccessToast("Customer updated")
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	return (
		<>
			<GridContainer>
				<GridItem sm={6} md={4} lg={3}>
					<Label>Customer #</Label>
					<Heading variant="h2">{customer.customerNumber}</Heading>
				</GridItem>
				<GridItem sm={6} md={4} lg={3}>
					<Label>KBM Managed?</Label>
					<Heading variant="h2">
						{customer.isKbmManaged ?
							<>
								<Icon icon="check" color="gray" /> KBM managed
							</>
						:	"No"}
					</Heading>
				</GridItem>
			</GridContainer>

			<HeadingDivider {...sectionHeadingProps}>About Site</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<DetailsTabAbout customer={customer} update={handleUpdateCustomer} />
			</Box>

			<HeadingDivider {...sectionHeadingProps}>Finance</HeadingDivider>
			<Box {...sectionContentsContainer}>
				<DetailsTabFinance customer={customer} update={handleUpdateCustomer} />
			</Box>
		</>
	)
}
