import { FC, useState } from "react"

import { css } from "@emotion/react"

import { CarouselImage, makeApiErrorMessage, useSetCarouselImageOrder } from "@ncs/ncs-api"
import { extractNumber, moveListItem } from "@ncs/ts-utils"
import { Box, ExtendableModalProps, Heading, Modal, Paragraph, useToast } from "@ncs/web-legos"

export interface ArrangeModalProps extends ExtendableModalProps {
	images: CarouselImage[] // Only pass in the active images.
}

export const CarouselImageArrangeModal: FC<ArrangeModalProps> = ({ images, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [orderState, setOrderState] = useState(images.map((image) => image.id))

	const saveNewOrder = useSetCarouselImageOrder()

	const onChange = (currentIndex: number, selectedIndex: string) => {
		setOrderState((prev) => moveListItem(currentIndex, extractNumber(selectedIndex), prev))
	}

	const onSave = async () => {
		try {
			setIsSaving(true)
			await saveNewOrder({
				images: orderState,
			})
			makeSuccessToast("Carousel order updated")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const reset = () => {
		setIsSaving(false)
		setErrorText(null)
		setOrderState(images.map((image) => image.id))
	}

	return (
		<Modal
			title="Rearrange Carousel Images"
			onOpen={reset}
			{...rest}
			closeButtonText="Cancel"
			rightButtons={{
				buttonText: "Update Order",
				onClick: onSave,
				isLoading: isSaving,
			}}
			errorText={errorText}
		>
			<Paragraph mb={2}>
				Edit the order in which carousel images display for customers.
			</Paragraph>

			<Box display="flex" mb={0.5}>
				<Heading variant="h6" bold width={3.8}>
					Rank
				</Heading>
				<Heading variant="h6" bold>
					Carousel Image
				</Heading>
			</Box>

			{orderState.map((id, i) => {
				const image = images.find((c) => c.id === id)
				if (!image) return null

				return (
					<Box key={image.id} display="flex" alignItems="center" columnGap={1} mb={0.5}>
						<select value={i} onChange={(e) => onChange(i, e.target.value)}>
							{images.map((c, j) => (
								<option key={`${image.id}${j}`} value={j}>
									{j + 1}
								</option>
							))}
						</select>
						<Box width={6}>
							<img
								src={image.desktopImageUrl}
								css={css`
									height: 1rem;
								`}
								alt={image.text ?? image.name ?? "carousel"}
							/>
						</Box>
						<Paragraph bold ml={1}>
							{image.name}
						</Paragraph>
					</Box>
				)
			})}
		</Modal>
	)
}
