import { FC, useState } from "react"

import { useHistory, useParams } from "react-router-dom"

import { APPLICATION, useUserCanUse, useUserProfile } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Button,
	Card,
	encodeUrlState,
	Icon,
	LoadingSpinner,
	Tabs,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

import { UserManagementUrlState } from "./UserManagement"
import { UserProfileDetailsTab } from "./UserProfileDetailsTab"
import { UserProfilePermissionsTab } from "./UserProfilePermissionsTab"
import { UserProfileTerritoriesTab } from "./UserProfileTerritoriesTab"
import { CreateUserCustomerModal } from "./CreateUserCustomerModal"

export enum UserProfileTab {
	Details = "Details",
	Territories = "Territories",
	Customers = "Customers",
	Permissions = "Permissions",
}

const UserProfile: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory<{ params?: UserManagementUrlState }>()
	const { userId } = useParams<{ userId: string }>()
	const [user, userLoading] = useUserProfile(userId)
	const [showCreateCustomerModal, setShowCreateCustomerModal] = useState(false)
	const canCreateCustomer = useUserCanUse(APPLICATION.UserManagementCreateCustomer)
	const [{ tab }, { updateUrlValue }] = useUrlState<{ tab: UserProfileTab }>({
		tab: UserProfileTab.Details,
	})

	if (userLoading) {
		return <LoadingSpinner />
	}
	if (!user) {
		return <Card>Invalid user ID: {userId}</Card>
	}

	return (
		<>
			<PageTitle title={`${user.firstName} ${user.lastName} | User Management`} />

			<Button
				icon="long-arrow-left"
				onClick={() =>
					history.push({
						pathname: "/user-management",
						search: encodeUrlState<UserManagementUrlState>(
							history.location.state?.params
						),
					})
				}
				containerProps={{ mb: 4 }}
			>
				All users
			</Button>

			<Card
				heading={`User: ${user.firstName} ${user.lastName}`}
				headingIcon="user"
				headingDetail={
					user.lastLogin ? `Last login ${formatDateTime(user.lastLogin)}` : undefined
				}
				renderRight={() =>
					canCreateCustomer &&
					user.employeeNumber &&
					(user.techCustomer ?
						<Button
							icon="edit"
							variant="primary-cta"
							onClick={() => {
								window.open(`/customer-management/${user.techCustomer.customerId}`)
							}}
						>
							Open Customer {user.techCustomer.customerNumber}
						</Button>
					:	<Button
							icon="edit"
							variant="primary-cta"
							onClick={() => {
								setShowCreateCustomerModal(true)
							}}
						>
							Create Customer
						</Button>)
				}
			>
				<Tabs
					currentTab={tab}
					onChange={(newTab) => updateUrlValue("tab", newTab)}
					panels={[
						{
							navLabel: UserProfileTab.Details,
							navIcon: <Icon icon="user-cog" />,
							component: <UserProfileDetailsTab user={user} />,
						},
						{
							navLabel: UserProfileTab.Territories,
							navIcon: <Icon icon="location-check" />,
							component: <UserProfileTerritoriesTab user={user} />,
						},
						{
							navLabel: UserProfileTab.Permissions,
							navIcon: <Icon icon="shield-halved" family="solid" />,
							component: <UserProfilePermissionsTab user={user} />,
						},
					]}
				/>
			</Card>

			<CreateUserCustomerModal
				userId={userId}
				userPhone={user.mobile}
				isOpen={showCreateCustomerModal}
				onClose={() => setShowCreateCustomerModal(false)}
			/>
		</>
	)
}

export default UserProfile
