import { FC, useState } from "react"

import { useFormContext } from "react-hook-form"

import { makeApiErrorMessage, useUploadCarouselImage } from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"
import { ErrorText, FileInput, FileType, Heading, Paragraph, useToast } from "@ncs/web-legos"

import { CarouselImageForm } from "./carousel-utils"

export const CarouselImages: FC = () => {
	const { makeErrorToast } = useToast()
	const {
		setValue,
		watch,
		formState: { errors },
	} = useFormContext<CarouselImageForm>()
	const [mobileImageUrl, tabletImageUrl, desktopImageUrl, tvImageUrl] = watch([
		"mobileImageUrl",
		"tabletImageUrl",
		"desktopImageUrl",
		"tvImageUrl",
	])
	const [saving, setSaving] = useState<
		"mobileImageUrl" | "tabletImageUrl" | "desktopImageUrl" | "tvImageUrl" | null
	>(null)

	const uploadImage = useUploadCarouselImage()

	const onSelect = async (
		image: File,
		role: "mobileImageUrl" | "tabletImageUrl" | "desktopImageUrl" | "tvImageUrl"
	) => {
		try {
			setSaving(role)
			const data = new FormData()
			data.append("file", image)
			const response = await uploadImage(data)
			const { imageUrl } = unpythonify(response.data)
			setValue(role, imageUrl, { shouldValidate: true })
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setSaving(null)
		}
	}

	return (
		<>
			<Heading variant="h4" mt={1}>
				Desktop
			</Heading>
			<Paragraph small color="secondary" my={0.5}>
				{desktopImageUrl || "(None selected yet)"}
			</Paragraph>
			<FileInput
				id="desktop-image-input"
				onChange={(file) => onSelect(file, "desktopImageUrl")}
				label="Upload Carousel Desktop Image"
				isLoading={saving === "desktopImageUrl"}
				fileTypes={[FileType.Images]}
			/>
			{!!errors.desktopImageUrl && <ErrorText>{errors.desktopImageUrl?.message}</ErrorText>}

			<Heading variant="h4" mt={2}>
				Mobile
			</Heading>
			<Paragraph small color="secondary" my={0.5}>
				{mobileImageUrl || "None selected yet (Optional)"}
			</Paragraph>
			<FileInput
				id="mobile-image-input"
				onChange={(file) => onSelect(file, "mobileImageUrl")}
				label="Upload Carousel Mobile Image"
				isLoading={saving === "mobileImageUrl"}
				fileTypes={[FileType.Images]}
			/>
			{!!errors.mobileImageUrl && <ErrorText>{errors.mobileImageUrl?.message}</ErrorText>}

			<Heading variant="h4" mt={2}>
				Tablet
			</Heading>
			<Paragraph small color="secondary" my={0.5}>
				{tabletImageUrl || "None selected yet (Optional)"}
			</Paragraph>
			<FileInput
				id="tablet-image-input"
				onChange={(file) => onSelect(file, "tabletImageUrl")}
				label="Upload Carousel Tablet Image"
				isLoading={saving === "tabletImageUrl"}
				fileTypes={[FileType.Images]}
			/>
			{!!errors.tabletImageUrl && <ErrorText>{errors.tabletImageUrl?.message}</ErrorText>}

			<Heading variant="h4" mt={2}>
				TV
			</Heading>
			<Paragraph small color="secondary" my={0.5}>
				{tvImageUrl || "None selected yet (Optional)"}
			</Paragraph>
			<FileInput
				id="tv-image-input"
				onChange={(file) => onSelect(file, "tvImageUrl")}
				label="Upload Carousel TV Image"
				isLoading={saving === "tvImageUrl"}
				fileTypes={[FileType.Images]}
			/>
			{!!errors.tvImageUrl && <ErrorText>{errors.tvImageUrl?.message}</ErrorText>}
		</>
	)
}
