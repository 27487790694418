import React, { memo, useMemo } from "react"
import { css, useTheme } from "@emotion/react"
import { Box, BoxProps } from "../layout"
import { SkeletonRows } from "../transitions"

export interface ParagraphProps extends BoxProps {
	id?: string
	light?: boolean
	bold?: boolean
	small?: boolean
	tiny?: boolean
	secondary?: boolean | string
	color?:
		| "primary"
		| "secondary"
		| "white"
		| "success"
		| "error"
		| "warning"
		| "button"
		| `#${string}` //For HEX values
		| any
	code?: boolean
	ellipsisOverflow?: boolean
	breakAnywhere?: boolean
	isLoading?: boolean
	className?: string
	textCenter?: boolean
	customColor?: string
	fontSize?: number | string
	textTransform?: string
}

export const Paragraph: React.FC<ParagraphProps> = memo(
	({
		id,
		light,
		bold,
		small,
		tiny,
		secondary,
		color,
		code,
		ellipsisOverflow,
		breakAnywhere,
		isLoading,
		className,
		children,
		textCenter,
		textTransform,
		customColor,
		fontSize,
		...rest
	}) => {
		const theme = useTheme()

		const paragraphStyles = useMemo(() => {
			return css`
				font-family: ${code ? "courier new" : undefined};
				font-weight: ${bold ? "bold"
				: light ? "light"
				: "normal"};
				font-size: ${fontSize ? `${fontSize}rem`
				: small ? "0.875rem"
				: tiny ? "0.75rem"
				: "1rem"};
				color: ${customColor ? customColor
				: color === "secondary" || secondary ? theme.palette.text.secondary
				: color === "white" ? "white"
				: color === "success" ? theme.palette.success.main
				: color === "error" ? theme.palette.error.main
				: color === "warning" ? theme.palette.warning.dark
				: color === "button" ? theme.palette.primary.main
				: theme.palette.text.primary};
				white-space: ${ellipsisOverflow ? "nowrap" : undefined};
				overflow-x: ${ellipsisOverflow ? "hidden" : undefined};
				text-overflow: ${ellipsisOverflow ? "ellipsis" : undefined};
				word-break: ${breakAnywhere ? "break-all" : undefined};
				margin: 0;
				text-align: ${textCenter ? "center" : "initial"};
				text-transform: ${textTransform ?? "none"};
			`
		}, [
			light,
			bold,
			color,
			small,
			ellipsisOverflow,
			breakAnywhere,
			code,
			theme,
			secondary,
			tiny,
			customColor,
		])

		return (
			<Box {...rest}>
				{isLoading ?
					<SkeletonRows
						containerProps={{
							display: "inline-block",
							width: "50%",
						}}
						rows={1}
						rowProps={{ my: 0 }}
					/>
				:	<p id={id} className={className} css={paragraphStyles}>
						{children}
					</p>
				}
			</Box>
		)
	}
)

Paragraph.displayName = "Paragraph"
