import { z } from "zod"

import { CarouselImage, CarouselImagePatch, CarouselImagePost } from "@ncs/ncs-api"

export const CarouselImageFormSchema = z.object({
	name: z.string().min(1, "Required"),
	desktopImageUrl: z.string().min(1, "Required"),
	mobileImageUrl: z.string(),
	tabletImageUrl: z.string(),
	tvImageUrl: z.string(),
	text: z.string(),
	destinationUrl: z.string(),
	destinationOpensNewWindow: z.boolean(),
	isActive: z.boolean(),
})

export type CarouselImageForm = z.infer<typeof CarouselImageFormSchema>

export const carouselImageFormDefaults: CarouselImageForm = {
	name: "",
	text: "",
	destinationUrl: "",
	destinationOpensNewWindow: false,
	desktopImageUrl: "",
	mobileImageUrl: "",
	tabletImageUrl: "",
	tvImageUrl: "",
	isActive: true,
}

export const getFormValuesFromImage = (image?: CarouselImage): CarouselImageForm => {
	const result: CarouselImageForm = {
		name: image?.name || carouselImageFormDefaults.name,
		text: image?.text || carouselImageFormDefaults.text,
		destinationUrl: image?.destinationUrl || carouselImageFormDefaults.destinationUrl,
		destinationOpensNewWindow:
			image?.destinationOpensNewWindow ??
			carouselImageFormDefaults.destinationOpensNewWindow,
		desktopImageUrl: image?.desktopImageUrl || carouselImageFormDefaults.desktopImageUrl,
		mobileImageUrl: image?.mobileImageUrl || carouselImageFormDefaults.mobileImageUrl,
		tabletImageUrl: image?.tabletImageUrl || carouselImageFormDefaults.tabletImageUrl,
		tvImageUrl: image?.tvImageUrl || carouselImageFormDefaults.tvImageUrl,
		isActive: image?.isActive ?? carouselImageFormDefaults.isActive,
	}

	return result
}

export const isSearchPageUrl = (url: string): boolean => {
	const startsWith = [
		"/shop/search",
		"https://pluto.ncsinternal.com/shop/search",
		"https://portal.ncsinternal.com/shop/search",
		"portal.ncsinternal.com/shop/search",
	]

	return startsWith.some((chunk) => url.startsWith(chunk))
}

export const carouselImageFormPayload = (
	form: CarouselImageForm,
	presentationOrder: number
): CarouselImagePost | CarouselImagePatch => {
	const result: CarouselImagePost | CarouselImagePatch = {
		...form,
		presentationOrder,
		destinationOpensNewWindow:
			isSearchPageUrl(form.destinationUrl) ? false : form.destinationOpensNewWindow,
	}

	return result
}
