import React from "react"
import { css } from "@emotion/react"
import { Link } from "react-router-dom"
import { ChevronRight } from "@material-ui/icons"

export interface BreadcrumbsEcommProps {
	pages?: {
		name: string
		to?: string
	}[]
}

export const BreadcrumbsEcomm: React.FC<BreadcrumbsEcommProps> = React.memo(({ pages = [] }) => {
	return (
		<nav css={container} aria-label="Breadcrumb">
			{pages.length > 0 && (
				<ul>
					<li>
						<Link to="/" aria-label="Home">
							Home
						</Link>
					</li>
					{pages.map(({ name, to }, i) => (
						<React.Fragment key={name}>
							<li aria-hidden="true">
								<ChevronRight css={separator} />
							</li>
							<li>
								{i === pages.length - 1 ?
									<Link to={to ?? "/"} aria-current="page">
										{name}
									</Link>
								:	<Link to={to ?? "/"}>{name}</Link>}
							</li>
						</React.Fragment>
					))}
				</ul>
			)}
		</nav>
	)
})

const container = css`
	margin: 0;

	ul {
		list-style: none;
		padding: 0;
		display: flex;
		align-items: center;
	}

	li {
		display: flex;
		align-items: center;
	}

	a {
		color: #6b7280;
		font-size: 0.875rem;
		padding: 0.5rem;
		padding-left: 0;
		padding-right: 0;
		text-decoration: none;
		font-weight: 500;
		cursor: pointer;
	}

	a:focus,
	a:hover {
		color: #0b75e1;
		outline: none;
	}

	a[aria-current="page"] {
		color: #1f2937;
		font-weight: bold;
		cursor: default;
	}
`

const separator = css`
	margin: 0 0.5rem;
	color: #9ca3af;
`
