import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PosPartClearanceSelectorProps
	extends ExtendableSelectProps<(typeof options)[number]> {}

const options = [
	{
		text: "Clearance",
		value: "clearance",
	},
]

export const PosPartClearanceSelector: FC<PosPartClearanceSelectorProps> = ({ ...rest }) => {
	return <Select label="Clearance products" fillContainer {...rest} options={options} />
}
