import React from "react"
import { APPLICATION, useAuth } from "@ncs/ncs-api"

function toBoolean(value: string) {
	return value.trim().toLowerCase() === "true"
}

export const Flag = {
	BASE: toBoolean(import.meta.env.VITE_FLAG_BASE || "false"),
	HOME: toBoolean(import.meta.env.VITE_FLAG_HOME || "false"),
	PDP: toBoolean(import.meta.env.VITE_FLAG_PDP || "false"),
	CHECKOUT: toBoolean(import.meta.env.VITE_FLAG_CHECKOUT || "false"),
	QUICK_ORDER: toBoolean(import.meta.env.VITE_FLAG_QUICK_ORDER || "false"),
}

type FeatureFlagProps = {
	old?: React.ReactElement
	children?: React.ReactElement
	flag: boolean
}

export const FeatureFlag: React.FC<FeatureFlagProps> = ({ children, old, flag }) => {
	const { user } = useAuth()
	const component = flag || user?.apps?.includes(APPLICATION.BetaFeatures) ? children : old
	return component ?? null
}

export const useFeatureFlag = (flag: boolean, oldComponent: React.FC, newComponent: React.FC) => {
	const { user } = useAuth()
	return flag || user?.apps?.includes(APPLICATION.BetaFeatures) ? newComponent : oldComponent
}
