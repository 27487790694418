import { FC, useState } from "react"
import { z } from "zod"

import {
	makeApiErrorMessage,
	useCreateCustomerFromUser,
	CreateCustomerFromUserPost,
} from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	PhoneInputFormField,
	StateSelectorFormField,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"

export interface CreateUserCustomerModalProps extends ExtendableModalProps {
	userId: string
	userPhone: string | null
}

const UserCustomerFormSchema = z.object({
	address2: z.string({ required_error: "Required" }).min(1, "Address Required"),
	address1: z.string().nullable(),
	city: z.string({ required_error: "City is Required" }).min(1, "City Required"),
	state: z.string({ required_error: "State is Required" }).min(2, "State Required"),
	zip: z.string().min(1, " Postal Code Required"),
	mobile: z.string().max(20, "Required"),
})

export type UserCustomerForm = z.infer<typeof UserCustomerFormSchema>

export const CreateUserCustomerModal: FC<CreateUserCustomerModalProps> = ({ userId, ...rest }) => {
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<UserCustomerForm>({
		resolver: zodResolver(UserCustomerFormSchema),
		defaultValues: {
			address1: "",
			address2: "",
			city: "",
			state: "",
			zip: "",
			mobile: rest.userPhone ?? undefined,
		},
	})
	const { makeSuccessToast, makeErrorToast } = useToast()
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const createCustomer = useCreateCustomerFromUser(userId)

	const handleUserCustomerCreate = async (formData: UserCustomerForm) => {
		try {
			setIsSaving(true)
			const createCustomerPost: CreateCustomerFromUserPost = {
				address1: formData.address1 ? formData.address1 : "",
				address2: formData.address2,
				address3: "",
				city: formData.city,
				state: formData.state,
				postalCode: formData.zip,
				phoneNumber: formData.mobile,
			}
			await createCustomer(createCustomerPost)
			makeSuccessToast("Site added to user")
			rest.onClose()
		} catch (e) {
			setIsSaving(false)
			makeErrorToast(makeApiErrorMessage(e))
		}
	}

	const onOpen = () => {
		reset()
		setErrorText(null)
	}

	return (
		<Modal
			{...rest}
			onOpen={onOpen}
			title="Create Customer For User"
			errorText={errorText}
			rightButtons={{
				buttonText: "Create Customer",
				onClick: handleSubmit(handleUserCustomerCreate),
				isLoading: isSaving,
			}}
		>
			<TextInputFormField
				control={control}
				errorStyle={errors.address2?.message}
				name="address2"
				label="Address"
			/>
			<TextInputFormField
				control={control}
				errorStyle={errors.address1?.message}
				name="address1"
				label="Attn, suite, etc"
			/>
			<TextInputFormField
				control={control}
				errorStyle={errors.city?.message}
				name="city"
				label="City"
			/>
			<StateSelectorFormField control={control} name="state" label="State" />
			<TextInputFormField
				control={control}
				errorStyle={errors.zip?.message}
				name="zip"
				label="Postal Code"
			/>
			<PhoneInputFormField control={control} name="mobile" label="Phone Number" />
		</Modal>
	)
}
