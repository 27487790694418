import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react"

import { css, Theme, useTheme } from "@emotion/react"
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core"

import { usePrevious, useScreenSizeMatch } from "../../util"
import { Button, ButtonProps, IconButton } from "../buttons"
import { Box } from "../layout"
import { AnimatedEntrance } from "../transitions"
import { ErrorText, Heading, Paragraph } from "../typography"
import { ConfirmCloseModal } from "./ConfirmCloseModal"

export interface ModalProps {
	isOpen?: boolean
	/** Fires when closed. Usually changes something in the parent to hide the modal. */
	onClose: () => void
	/** Fires when modal goes from closed to open. Useful for resetting things, as if the modal
	 * was mounting/unmounting between closing and opening. */
	onOpen?: () => void
	/**
	 * Fires when the modal is closed via any of the close buttons or clicking outside.
	 * Does not fire automatically though when the modal closes (so the right buttons will
	 * not trigger this).
	 */
	onCancel?: () => void
	/** Large text in the top left. */
	title?: string | null
	/** Smaller text that sits next to the title. */
	titleDetail?: string | null
	/** How big should the modal be? */
	maxWidth?: DialogProps["maxWidth"]
	/** Should the modal always take up the full height of the screen (not including margins)? */
	fullHeight?: boolean
	/** Declare a min height for `DialogContent`. */
	contentMinHeight?: string
	/** Should the modal be full screen? */
	fullScreen?: boolean
	closeButtonText?: string
	showCloseButton?: boolean
	/** Pass in props to render one or more buttons. */
	rightButtons?: ButtonProps | ButtonProps[]
	/** Pass in props to render one or more buttons. */
	leftButtons?: ButtonProps | ButtonProps[]
	/** Error text to show above the bottom buttons. */
	errorText?: string | null
	/** Should the error align to the left or right? */
	errorAlignment?: "left" | "right"
	/** Arbitrary content that sits below the title, sticky at the top if DialogContents scrolls. */
	stickyTopContent?: ReactNode
	/** Arbitrary content that sits just above the footer button controls. */
	aboveFooterContent?: ReactNode
	/** Should the modal close when you click outside of it? */
	disableBackdropClick?: boolean
	/** Should the modal close if you hit the escape key? */
	disableEscapeKeyDown?: boolean
	/** Confirm before closing the modal? */
	confirmClose?: boolean
	/** The message for when you confirm the closing. */
	confirmCloseMessage?: string
}

export interface ExtendableModalProps {
	isOpen?: ModalProps["isOpen"]
	onClose: ModalProps["onClose"]
	onOpen?: ModalProps["onOpen"]
	title?: ModalProps["title"]
	titleDetail?: ModalProps["titleDetail"]
}

export const Modal: React.FC<ModalProps> = React.memo(
	({
		title,
		titleDetail,
		onClose,
		onOpen,
		onCancel,
		maxWidth = "sm",
		fullHeight,
		contentMinHeight,
		fullScreen,
		closeButtonText = "CLOSE",
		showCloseButton = true,
		isOpen: isOpenProp = true,
		rightButtons,
		leftButtons,
		errorText,
		errorAlignment = "right",
		stickyTopContent,
		aboveFooterContent,
		disableBackdropClick,
		disableEscapeKeyDown,
		confirmClose,
		confirmCloseMessage,
		children,
	}) => {
		const theme = useTheme()
		const screenIsTiny = useScreenSizeMatch("xs")
		const [isConfirmingClose, setIsConfirmingClose] = useState(false)

		// By proxying the open-ness through local state, we can manually keep from
		// firing onClose until the modal's exit animation is finished. This avoids
		// an unpleasant flash of data-less modal as it transitions out.
		const [isOpenLocal, setIsOpenLocal] = useState(isOpenProp)

		const leftButtonsArray = useMemo(
			() =>
				Array.isArray(leftButtons) ? leftButtons
				: leftButtons ? [leftButtons]
				: [],
			[leftButtons]
		)
		const rightButtonsArray = useMemo(
			() =>
				Array.isArray(rightButtons) ? rightButtons
				: rightButtons ? [rightButtons]
				: [],
			[rightButtons]
		)

		const closeModal = useCallback(
			(isCancel = true) => {
				setIsOpenLocal(false)

				// Let the closing animation mostly finish before firing onClose.
				setTimeout(() => {
					if (isCancel && onCancel) {
						onCancel()
					}
					onClose()
				}, theme.transitions.duration.leavingScreen - 100)
			},
			[onClose, onCancel, theme]
		)

		const tryToCloseModal = useCallback(() => {
			if (confirmClose) {
				setIsConfirmingClose(true)
			} else {
				closeModal()
			}
		}, [closeModal, confirmClose])

		// Listen for the prop changing, so we can keep our local state up-to-date.
		// The parent is still driving the show, we're just pumping the brakes
		// when they tell us to close.
		const prevIsOpenProp = usePrevious(isOpenProp)
		useEffect(() => {
			if (isOpenProp && !prevIsOpenProp) {
				setIsOpenLocal(true)

				// Fire the onOpen prop if you passed it on.
				if (onOpen) onOpen()
			} else if (!isOpenProp && prevIsOpenProp) {
				closeModal(false)
			}
		}, [prevIsOpenProp, isOpenProp, closeModal, onOpen])

		const dialogCss = useMemo(() => {
			const result = []

			// The default padding interferes with Table's sticky header.
			result.push(css`
				.MuiDialogContent-root {
					padding-top: 0;
					padding-bottom: 0;
				}
			`)
			if (fullHeight) {
				result.push(css`
					> .MuiDialog-container > .MuiDialog-paper {
						height: 100%;
					}
				`)
			}

			return result
		}, [fullHeight])

		const dialogTitleCss = useMemo(() => {
			return css`
				box-shadow: ${stickyTopContent ? "0 0 6px rgba(0, 0, 0, 0.08)" : undefined};
			`
		}, [stickyTopContent])

		const dialogContentInnerCss = useMemo(() => {
			return css`
				min-height: ${contentMinHeight ?? "auto"};
				padding-bottom: 2rem;
			`
		}, [contentMinHeight])

		const dialogActionsCss = useMemo(() => {
			// return css`
			// 	box-shadow: ${contentScrolls ? "0 -1px 13px rgba(0, 0, 0, 0.13)" : undefined};
			// `
			return css`
				box-shadow: 0 0 6px rgba(0, 0, 0, 0.08);
			`
		}, [])

		const showFooter = !!(
			leftButtonsArray.length ||
			rightButtonsArray.length ||
			showCloseButton ||
			aboveFooterContent
		)

		return (
			<>
				<Dialog
					open={isOpenLocal}
					onClose={(e, reason) => {
						if (
							(!disableBackdropClick || reason !== "backdropClick") &&
							(!disableEscapeKeyDown || reason !== "escapeKeyDown")
						) {
							tryToCloseModal()
						}
					}}
					maxWidth={maxWidth}
					fullWidth={!!maxWidth}
					fullScreen={fullScreen || (screenIsTiny && typeof fullScreen === "undefined")}
					scroll="paper"
					css={dialogCss}
				>
					<IconButton
						icon="times"
						family="light"
						title="Close"
						onClick={tryToCloseModal}
						css={iconCloseButtonStyle}
					/>
					<DialogTitle disableTypography css={dialogTitleCss}>
						{(!!title || !!titleDetail) && (
							<Box display="flex" flexWrap="wrap" mt={0.5}>
								{title && (
									<Heading variant="h2" css={headingStyle}>
										{title}
									</Heading>
								)}
								{titleDetail && (
									<Paragraph
										mb={0.15}
										mr={1}
										color="secondary"
										alignSelf="flex-end"
									>
										{titleDetail}
									</Paragraph>
								)}
							</Box>
						)}
						{!!stickyTopContent && <Box mt={1.5}>{stickyTopContent}</Box>}
					</DialogTitle>

					<DialogContent>
						<div css={dialogContentInnerCss}>{children}</div>
					</DialogContent>

					{showFooter && (
						<DialogActions css={dialogActionsCss}>
							<footer css={footerStyle}>
								{!!aboveFooterContent && <Box mb={1}>{aboveFooterContent}</Box>}

								<AnimatedEntrance show={!!errorText} direction="fade" mb={1}>
									<ErrorText
										display="flex"
										justifyContent={
											errorAlignment === "right" ? "flex-end" : "flex-start"
										}
									>
										{errorText}
									</ErrorText>
								</AnimatedEntrance>

								<div css={innerFooterStyle}>
									<div>
										{leftButtonsArray.map((buttonProps, i) => (
											<Button
												key={buttonProps.buttonText ?? i}
												{...buttonProps}
												variant={buttonProps.variant ?? "primary-cta"}
											/>
										))}
									</div>
									<div>
										{showCloseButton && (
											<Button
												variant="clear"
												onClick={tryToCloseModal}
												buttonText={closeButtonText}
											/>
										)}
										{rightButtonsArray.map((buttonProps, i) => (
											<Button
												key={buttonProps.buttonText ?? i}
												{...buttonProps}
												variant={buttonProps.variant ?? "primary-cta"}
											/>
										))}
									</div>
								</div>
							</footer>
						</DialogActions>
					)}
				</Dialog>

				{isConfirmingClose && (
					<ConfirmCloseModal
						message={confirmCloseMessage}
						handleConfirm={closeModal}
						handleCancel={() => setIsConfirmingClose(false)}
					/>
				)}
			</>
		)
	}
)

const headingStyle = css`
	// MUI really wants to make the modal title an h6, which is
	// uppercase, which we don't want here.
	text-transform: none;
	margin-right: 1rem;
	h1,
	h2 {
		font-weight: bold;
	}
`
const iconCloseButtonStyle = (theme: Theme) => css`
	position: absolute;
	top: 0.65rem;
	right: 0.65rem;
	width: 36px;
	height: 36px;
	vertical-align: middle;
	color: ${theme.palette.text.secondary};
	z-index: 1;

	${theme.breakpoints.down("sm")} {
		svg.svg-inline--fa,
		svg {
			width: 24px;
			height: 24px;
			margin-bottom: 4px;
			margin-left: 1px;
		}
	}
`
const footerStyle = css`
	flex-grow: 1;
	padding: 1rem;
`
const innerFooterStyle = (theme: Theme) => css`
	display: flex;
	justify-content: space-between;
	flex-grow: 1;
	column-gap: 1rem;
	> div {
		column-gap: 2rem;
		display: flex;
		align-self: center;
	}
	${theme.breakpoints.down("sm")} {
		row-gap: 1rem;
		> div {
			row-gap: 2rem;
			div {
				justify-content: center;
			}
		}
	}
`

Modal.displayName = "Modal"
