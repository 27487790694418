import { FC, useState } from "react"

import { Cell, Column } from "react-table"

import {
	APPLICATION,
	FreightCap,
	FreightCapTabQueryParams,
	useFreightCaps,
	useUserCanUse,
} from "@ncs/ncs-api"
import { formatCurrency } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Icon,
	IconButton,
	ReactTableSortType,
	Table,
	Tooltip,
	useUrlState,
} from "@ncs/web-legos"

import { ActiveOnlyQueryFilter } from "~/components"

import {
	EditFreightCapModal,
	ExportFreightCapModal,
	getFreightTypeDescription,
} from "./components"

export type FreightCapTabUrlState = FreightCapTabQueryParams

export const FreightCapTab: FC = () => {
	const canEdit = useUserCanUse(APPLICATION.FreightRatesAdmin)
	const [showCreate, setShowCreate] = useState(false)
	const [showExportModel, setShowExportModel] = useState(false)
	const [freightCapState, setFreightCapState] = useState<FreightCap | null>(null)

	const [params, { setUrlState: setParams }] =
		useUrlState<FreightCapTabUrlState>(defaultUrlState)

	const [caps, capsLoading] = useFreightCaps({ params })

	const editFreightCap = (freightCap: FreightCap) => {
		setFreightCapState(freightCap)
		setShowCreate(true)
	}

	const columns: Column<FreightCap>[] = [
		{
			Header: "Freight cap",
			accessor: "threshold",
			Cell: ({ row: { original } }: Cell<FreightCap>) => formatCurrency(original.threshold),
			sortType: ReactTableSortType.Number,
		},
		{
			Header: "Freight type",
			accessor: "freightCapType",
			Cell: ({ row: { original } }: Cell<FreightCap>) => {
				const freightTypeIdString = String(original.freightCapType)
				return getFreightTypeDescription(freightTypeIdString)
			},
		},
		{
			Header: "Freight cap active",
			accessor: "thresholdActive",
			Cell: ({ row: { original } }: Cell<FreightCap>) =>
				String(original.thresholdActive).toUpperCase(),
		},
		{
			Header: "Edit freight Cap",
			Cell: ({ row: { original } }: Cell<FreightCap>) => {
				let editIcon
				let tooltip
				if (canEdit) {
					editIcon = (
						<IconButton
							icon="pencil"
							color="primary"
							onClick={() => editFreightCap(original)}
						/>
					)
					tooltip = "Edit freight cap"
				} else {
					editIcon = <Icon icon="pencil" color="gray" />
					tooltip = "You do not have permission to edit freight cap"
				}

				return (
					<>
						<Box display="flex" alignItems="center" gap={0.5}>
							<Tooltip title={tooltip}>{editIcon}</Tooltip>
						</Box>
					</>
				)
			},
		},
	]

	return (
		<>
			{canEdit && (
				<Box textAlign="right" mb={4}>
					<Button
						variant="secondary-cta"
						icon="plus-circle"
						onClick={() => setShowExportModel(true)}
					>
						Export Freight Cap Edit History
					</Button>
				</Box>
			)}

			<Table
				data={caps ?? []}
				columns={columns}
				isLoading={capsLoading}
				toggledQueryFilters={[ActiveOnlyQueryFilter]}
				queryParamState={params}
				setQueryParamState={setParams}
			/>

			{showCreate && (
				<EditFreightCapModal
					freightCapProp={freightCapState}
					onClose={() => setShowCreate(false)}
				/>
			)}

			{showExportModel && (
				<ExportFreightCapModal onClose={() => setShowExportModel(false)} />
			)}
		</>
	)
}

const defaultUrlState: FreightCapTabUrlState = {
	active: null,
}
