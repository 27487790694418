import React, { memo, MouseEvent, useMemo, useState } from "react"

import { css, Theme, useTheme } from "@emotion/react"
import { ButtonBase, ButtonBaseProps, Popover } from "@material-ui/core"
import { transparentize } from "polished"

import { Box, BoxProps } from "../layout"
import { LoadingSpinner } from "../transitions"
import { Icon, IconFamily, IconName } from "../typography"

export interface ButtonEcommProps extends Omit<ButtonBaseProps, "onChange" | "color"> {
	variant?:
		| "text" // Looks the same as an `a` tag. This is default.
		| "primary-cta" // Primary call-to-action.
		| "secondary-cta" // Secondary call-to-action.
		| "tertiary-cta" // Secondary call-to-action.
		| "clear" // Button with a clear background, still has padded dimensions.
		| "table" // Kinda like `text`, but tweaked for use in a table.
	icon?: IconName
	trailingIcon?: IconName
	iconFamily?: IconFamily
	spinIcon?: boolean
	fixedWidthIcon?: boolean
	/** Sugar for setting width to 100%. */
	fillContainer?: boolean
	width?: string
	containerProps?: BoxProps
	buttonText?: string
	isLoading?: boolean
	/* In most cases it's helpful to not allow the button text to wrap, but you can change here if needed.*/
	allowTextWrap?: boolean
	customVariant?: string
	/** Nested buttons */
	nestedButtons?: {
		buttonText: string
		onClick: () => void
		disabled?: boolean
		isLoading?: boolean
	}[]
	/**
	 * Stop the click event from propagating.
	 */
	stopPropagation?: boolean
	customTextColor?: string | null
}

export const ButtonEcomm: React.FC<ButtonEcommProps> = memo(
	({
		variant = "text",
		icon,
		trailingIcon,
		iconFamily,
		spinIcon,
		fixedWidthIcon,
		fillContainer,
		width,
		containerProps,
		buttonText,
		isLoading,
		onClick,
		disabled,
		allowTextWrap,
		nestedButtons = [],
		stopPropagation,
		children,
		customVariant,
		customTextColor,
		...rest
	}) => {
		if (nestedButtons.length > 0 && variant !== "primary-cta" && variant !== "secondary-cta") {
			throw new Error(
				"Nested buttons are only supported when the button variant is primary or secondary CTA."
			)
		}

		const theme = useTheme()
		const [activeNestedButtonIndex, setActiveNestedButtonIndex] = useState<number | null>(null)
		const [nestedMenuAnchor, setNestedMenuAnchor] = useState<HTMLSpanElement | null>(null)

		const closeNestButtonsMenu = () => {
			setNestedMenuAnchor(null)
		}

		const variantStyle = useMemo(() => {
			switch (variant) {
				case "primary-cta": {
					return css`
						color: white;
						background: #0b75e1;
						border-color: "#0B75E1";
						padding: 1rem;
						border-radius: 0.5rem;
						.button-text {
							font-size: 1rem;
						}
						&:hover {
							background: #00254a;
							border-color: #00254a;
						}
					`
				}
				case "secondary-cta": {
					return css`
						color: #0b75e1;
						background: transparent;
						border-color: #0b75e1;
						padding: 1rem;
						.button-text {
							font-size: 1rem;
						}
						&:hover {
							background: #ffffff;
							color: #003264;
							border-color: #003264;
						}
					`
				}
				case "tertiary-cta": {
					return css`
						color: #ffffff;
						background: #0b75e1;
						border-color: #0b75e1;
						padding: 1rem;
						.button-text {
							font-size: 1rem;
						}
						&:hover {
							background: ${transparentize(0.95, theme.palette.cta.light)};
							color: ${theme.palette.cta.light};
							border-color: ${theme.palette.cta.light};
						}
					`
				}
				case "text": {
					return css`
						position: relative;
						padding: 0;
						border: 0;
						color: ${customTextColor || theme.palette.primary.main};
						flex-shrink: ${allowTextWrap ? 1 : 0};
						.button-text {
							position: relative;
							top: -1px;
							text-transform: none;
							font-size: 0.875rem;
							white-space: ${allowTextWrap ? undefined : "nowrap"};
							text-align: left;
						}
						&:hover {
							color: ${theme.palette.primary.light};
						}
					`
				}
				case "clear": {
					return css`
						padding: 1rem;
						color: ${theme.palette.text.secondary};
						border-color: transparent;
						.button-text {
							font-weight: normal;
						}
						&:hover {
							border-color: transparent;
						}
					`
				}
				case "table": {
					return css`
						padding: 0 0.5rem 0 0;
						border: 0;
						.button-text {
							position: relative;
							text-transform: none;
							font-size: 0.875rem;
							font-weight: normal;
						}
						svg {
							font-size: 0.9em;
							margin-right: 0.35rem;
						}
					`
				}
			}
		}, [variant, theme, allowTextWrap])

		const buttonWidthStyle = useMemo(() => {
			return css`
				// If you specify a width, we give that to the container.
				// This here ensures that our button inside the container will fill it
				// up to the width you asked.
				width: ${fillContainer || width ? "100%" : undefined};
			`
		}, [fillContainer, width])

		const nestedButtonsMenuList = useMemo(() => {
			// If no nested button is selected, then this list is just the list passed in.
			if (activeNestedButtonIndex === null) {
				return nestedButtons
			} else {
				return [
					// If one of the nested buttons is selected though, add the normal primary
					// button into this list, and remove the selected one.
					{
						buttonText,
						disabled,
						onClick: (e: MouseEvent<HTMLButtonElement>) => {
							// If you click the normal button when it's in the list, reset things back to normal.
							setActiveNestedButtonIndex(null)
							closeNestButtonsMenu()
							if (onClick) onClick(e)
						},
					},
					...nestedButtons.filter(
						(b) => b.buttonText !== nestedButtons[activeNestedButtonIndex]?.buttonText
					),
				]
			}
		}, [buttonText, nestedButtons, onClick, disabled, activeNestedButtonIndex])

		const nestedMenuOpen = !!nestedMenuAnchor
		const usingNestedButton = activeNestedButtonIndex !== null

		return (
			<Box display="inline-flex" width={fillContainer ? "100%" : width} {...containerProps}>
				{isLoading && (
					<div css={spinnerContainerStyle}>
						<LoadingSpinner py={0} />
					</div>
				)}

				<ButtonBase
					disableTouchRipple={variant === "text"}
					focusRipple
					{...rest}
					onClick={(e) => {
						if (stopPropagation) {
							e.stopPropagation()
						}
						if (usingNestedButton) {
							nestedButtons[activeNestedButtonIndex]?.onClick()
						} else {
							if (onClick) onClick(e)
						}
					}}
					disabled={disabled || isLoading}
					css={[
						baseButtonStyle,
						buttonWidthStyle,
						customVariant ? customVariantStyle(customVariant) : variantStyle,
					]}
				>
					{/* Leading icon */}
					{!usingNestedButton && icon && (
						<span className="leading-icon-container">
							<Icon
								css={iconStyle}
								icon={icon}
								family={iconFamily}
								spin={spinIcon}
								fixedWidth={fixedWidthIcon}
							/>
						</span>
					)}

					{/* Button text */}
					<span className="button-text">
						{usingNestedButton ?
							nestedButtons[activeNestedButtonIndex]?.buttonText
						:	buttonText ?? children}
					</span>

					{/* Trailing icon */}
					{!usingNestedButton && trailingIcon && (
						<span className="trailing-icon-container">
							<Icon
								css={iconStyle}
								icon={trailingIcon}
								family={iconFamily}
								spin={spinIcon}
								fixedWidth={fixedWidthIcon}
							/>
						</span>
					)}

					{/* Nested menu icon */}
					{nestedButtonsMenuList.length > 0 && (
						<span
							tabIndex={0}
							role="menu"
							css={nestedButtonsMenuButtonStyle}
							onKeyUp={(e) => {
								if (e.key === "Enter") {
									e.preventDefault()
									e.stopPropagation()
									setNestedMenuAnchor(e.currentTarget)
								}
							}}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								setNestedMenuAnchor(e.currentTarget)
							}}
						>
							<Icon icon="caret-down" family="solid" />
						</span>
					)}
				</ButtonBase>

				{/* Nested buttons menu */}
				{nestedButtonsMenuList.length > 0 && (
					<Popover
						open={nestedMenuOpen}
						anchorEl={nestedMenuAnchor}
						onClose={closeNestButtonsMenu}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						transformOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
					>
						<ul css={nestedButtonsMenuStyle}>
							{nestedButtonsMenuList.map((button, i) => (
								<li key={button.buttonText}>
									<button
										css={nestedMenuButtonLineStyle}
										onClick={(e) => {
											setActiveNestedButtonIndex(i)
											closeNestButtonsMenu()
											button.onClick(e)
										}}
										disabled={button.disabled}
									>
										{button.buttonText}
									</button>
								</li>
							))}
						</ul>
					</Popover>
				)}
			</Box>
		)
	}
)

const baseButtonStyle = (theme: Theme) => css`
	display: flex;
	align-items: center;
	flex-shrink: 0;
	padding: 0.8rem 1.75rem;
	border-width: 2px;
	border-style: solid;
	border-radius: ${theme.shape.borderRadius}px;
	transition-duration: ${theme.transitions.duration.shorter}ms;
	transition-timing-function: ${theme.transitions.easing.easeOut};
	transition-property: background-color, color, border-color;
	${theme.breakpoints.down("sm")} {
		flex-shrink: 1;
	}
	.leading-icon-container,
	.trailing-icon-container {
		position: relative;
		top: -1px;
		height: 15px;
		line-height: 1em;
	}
	.leading-icon-container {
		margin-right: 0.35rem;
	}
	.trailing-icon-container {
		margin-left: 0.35rem;
	}
	.button-text {
		display: inline-block;
		position: relative;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.2px;
		line-height: 1em;
	}
	&:hover {
		border-color: ${theme.palette.primary.main};
	}
	&.Mui-disabled {
		opacity: 0.35;
	}
`
const iconStyle = css`
	position: relative;
	font-size: 1rem;
`
const spinnerContainerStyle = css`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	z-index: 1;
	.fa-spinner-third {
		color: inherit;
	}
`
const nestedButtonsMenuButtonStyle = ({ palette }: Theme) => css`
	// Nudge that sucker over into place.
	margin-right: -1.8rem;
	margin-top: -1rem;
	margin-bottom: -1rem;
	margin-left: 1rem;
	padding: 0.75rem 1.25rem;
	border-left: 1px solid white;
	&:hover {
		background-color: ${palette.primary.light};
	}
`
const nestedButtonsMenuStyle = ({ palette }: Theme) => css`
	margin: 0;
	padding: 0;
	border: 2px solid ${palette.primary.main};
	li {
		margin: 0;
		padding: 0;
		border-bottom: 1px solid #eee;
		&:last-of-type {
			border-bottom: 0;
		}
	}
`
const nestedMenuButtonLineStyle = ({ palette }: Theme) => css`
	color: ${palette.primary.main};
	background: white;
	padding: 0.75rem 1rem;
	border: 0;
	width: 100%;
	text-align: left;
`

const customVariantStyle = (customVariant: string) => {
	return css`
		color: white;
		background: ${customVariant};
		border-color: ${customVariant};
		padding: 1rem 1.75rem;
		.button-text {
			font-size: 15px;
		}
		&:hover {
			background: ${customVariant};
			border-color: ${customVariant};
		}
	`
}
