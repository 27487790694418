import { FC, useState } from "react"

import { css } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { Column } from "react-table"

import { CarouselImage, useCarouselImages } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	EmptyValueDash,
	Icon,
	Paragraph,
	Table,
	useScrollToTopOnMount,
} from "@ncs/web-legos"

import { CarouselImageArrangeModal } from "./CarouselImageArrangeModal"

const columns: Column<CarouselImage>[] = [
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Desktop",
		accessor: "desktopImageUrl",
		Cell: ({ row: { original } }) => {
			return (
				<img
					src={original.desktopImageUrl}
					alt={original.text ?? original.name ?? "carousel"}
					css={logoCss}
				/>
			)
		},
	},
	{
		Header: "Text",
		accessor: "text",
	},
	{
		Header: "Last modified",
		accessor: "modifiedOn",
		Cell: ({
			row: {
				original: { modifiedOn },
			},
		}) => (modifiedOn ? formatDateTime(modifiedOn) : <EmptyValueDash />),
	},
	{
		Header: "Visible?",
		accessor: "isActive",
		Cell: ({ row: { original } }) =>
			original.isActive ? <Icon icon="check" color="gray" /> : <EmptyValueDash />,
	},
]

const Carousel: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory()
	const [showArrangeModal, setShowArrangeModal] = useState(false)

	const [images, imagesLoading] = useCarouselImages()

	return (
		<Card heading="Carousel" headingIcon="home">
			<Paragraph mb={2} maxWidth={40}>
				Carousel images on the Shop homepage highlight NCS promotions, new products, and
				special offers. Clicking these images directs customers to specific filters,
				categories, catalogs, products, brands, or any chosen page.
			</Paragraph>

			<Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={2}>
				<Button
					icon="plus-circle"
					variant="secondary-cta"
					onClick={() => history.push("/carousel/item")}
				>
					Create New Carousel Image
				</Button>

				<Button
					icon="sort"
					onClick={() => setShowArrangeModal(true)}
					disabled={!images || images.length < 2}
				>
					Rearrange carousel order
				</Button>
			</Box>

			<Table
				data={images ?? []}
				noDataText="No carousel images created yet"
				columns={columns}
				isLoading={imagesLoading}
				rowVerticalAlign="middle"
				onRowClick={({ original }) => history.push(`/carousel/item/${original.id}`)}
				disableAllSorting
			/>

			{!!images && images.length > 1 && (
				<CarouselImageArrangeModal
					isOpen={showArrangeModal}
					onClose={() => setShowArrangeModal(false)}
					images={images
						.filter((image) => image.isActive)
						.sort((a, b) => a.presentationOrder - b.presentationOrder)}
				/>
			)}
		</Card>
	)
}

const logoCss = css`
	height: 1.5rem;
`

export default Carousel
