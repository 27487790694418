import uniq from "lodash/uniq"
import { z } from "zod"

import { EquipmentVintageLookup, PosPart, PosPartPatch, PosPartPost } from "@ncs/ncs-api"
import { extractNumber } from "@ncs/ts-utils"

export const PosPartFormSchema = z
	.object({
		isActive: z.boolean(),
		relationship: z.literal("standalone").or(z.literal("parent")).or(z.literal("child")),
		parentPartId: z.string().nullable(),
		name: z.string().min(1, "Name is too short"),
		description: z.string().min(1, "Description is too short"),
		inventoryPartId: z.string().nullable(),
		posPartNumber: z.string().nullable(),
		price: z.number().nullable(),
		categoryIds: z.array(z.string()),
		vintageIds: z.array(z.string()),
		isFeatured: z.boolean(),
		isClearance: z.boolean(),
		flatShippingRate: z.number().nullable(),
		imageUrl: z.string().nullable(),
	})
	.refine((form) => !(form.relationship === "child" && !form.parentPartId), {
		message: "Assignment of a parent part is required for a child part",
		path: ["parentPartId"],
	})
	.refine((form) => !(form.relationship !== "parent" && !form.inventoryPartId), {
		message: "Required",
		path: ["inventoryPartId"],
	})
	.refine((form) => !(form.relationship !== "parent" && !form.posPartNumber), {
		message: "Required",
		path: ["posPartNumber"],
	})
	.refine((form) => !(form.relationship !== "parent" && form.price == null), {
		message: "Required",
		path: ["price"],
	})

export type PosPartForm = z.infer<typeof PosPartFormSchema>

export const posPartFormDefaultValues: PosPartForm = {
	name: "",
	description: "",
	isActive: true,
	relationship: "standalone",
	parentPartId: null,
	inventoryPartId: null,
	posPartNumber: null,
	price: null,
	categoryIds: [],
	vintageIds: [],
	isFeatured: false,
	isClearance: false,
	flatShippingRate: null,
	imageUrl: null,
}

export const getFormValuesFromPosPart = (part: PosPart): PosPartForm => {
	return {
		...posPartFormDefaultValues,
		isActive: part.isActive ?? false,
		relationship:
			part.isParent ? "parent"
			: part.isChild ? "child"
			: "standalone",
		name: part.title,
		description: part.description,
		inventoryPartId: part.part?.id ?? null,
		posPartNumber: part.onlinePartNumber ?? null,
		price: part.price != null ? extractNumber(part.price) : null,
		categoryIds: part.categories.map((category) => category.id.toString()),
		vintageIds: part.vintages.map((vintage) => vintage.id),
		isFeatured: part.featuredItem,
		isClearance: part.clearanceItem,
		flatShippingRate:
			part.flatShippingRate == null ? null : extractNumber(part.flatShippingRate),
		imageUrl: part.imageUrl ?? null,
	}
}

/**
 * Use this to overwrite existing values in the form with the values of the parent for
 * the fields where we want the child to inherit from the parent.
 */
export const updateChildFormWithParent = (
	childForm: PosPartForm,
	parent: PosPart | null
): PosPartForm => {
	const updatedForm: PosPartForm = {
		...childForm,
		parentPartId: parent?.id.toString() ?? posPartFormDefaultValues["parentPartId"],
		categoryIds:
			parent?.categories.map(({ id }) => id.toString()) ??
			posPartFormDefaultValues["categoryIds"],
		vintageIds: parent?.vintages.map(({ id }) => id) ?? posPartFormDefaultValues["vintageIds"],
		isFeatured: parent?.featuredItem ?? posPartFormDefaultValues["isFeatured"],
		isClearance: parent?.clearanceItem ?? posPartFormDefaultValues["isClearance"],
		imageUrl: parent?.imageUrl ?? posPartFormDefaultValues["imageUrl"],
	}

	return updatedForm
}

export const getVintageLabel = (vintage: EquipmentVintageLookup): string => {
	const pieces: string[] = []

	if (vintage.model?.manufacturer?.name) {
		pieces.push(vintage.model.manufacturer.name)
	}
	if (vintage.model?.modelName) {
		pieces.push(vintage.model.modelName)
	}
	if (vintage.name) {
		pieces.push(vintage.name)
	}

	return uniq(pieces).join(" - ")
}

export const posPartFormToPosPartPost = (
	formData: PosPartForm,
	imageFile: File | null
): PosPartPost => {
	const postData: PosPartPost = {
		description: formData.description,
		flatShippingRate: formData.flatShippingRate?.toString() ?? null,
		isShippedFlatRate: !!formData.flatShippingRate,
		isActive: formData.isActive,
		onlinePartNumber: formData.posPartNumber,
		partId: formData.inventoryPartId,
		price: formData.price,
		title: formData.name,
		categories: formData.categoryIds.join(","),
		vintages: formData.vintageIds.join(","),
		isParent: formData.relationship === "parent",
		isChild: formData.relationship === "child",
		parentPartId: formData.relationship === "child" ? formData.parentPartId : null,
		featuredItem: formData.isFeatured,
		clearanceItem: formData.isClearance,
		file: imageFile,
	}

	return postData
}

export const posPartFormToPosPartPatch = (formData: PosPartForm): PosPartPatch => {
	const patchData: PosPartPatch = {
		description: formData.description,
		flatShippingRate: formData.flatShippingRate?.toString() ?? null,
		isShippedFlatRate: !!formData.flatShippingRate,
		isActive: formData.isActive,
		onlinePartNumber: formData.posPartNumber,
		partId: formData.inventoryPartId,
		price: formData.price,
		title: formData.name,
		categories: formData.categoryIds.map((id) => ({ _id: id })),
		vintages: formData.vintageIds.map((id) => ({ _id: id })),
		isParent: formData.relationship === "parent",
		isChild: formData.relationship === "child",
		parentPartId: formData.relationship === "child" ? formData.parentPartId : null,
		featuredItem: formData.isFeatured,
		clearanceItem: formData.isClearance,
	}

	return patchData
}
