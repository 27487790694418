// ts-import-sorter: disable
import { APPLICATIONS } from "@ncs/mortar/util/constants"

// Dashboard
import { Dashboard } from "~/views/Dashboard"

// Service
import { ServiceRequestsDashboardContainer } from "~/views/Service/ServiceRequests"
import { Dispatches } from "~/views/Service/Dispatches/Dispatches/Dispatches"
import { DispatchDetail } from "~/views/Service/Dispatches/DispatchDetail/DispatchDetail"
import Quotes from "~/views/Quotes/Quotes"
import QuoteDetail from "~/views/Quotes/QuoteDetail/QuoteDetail"
import { PartOrders } from "~/views/PartOrders/PartOrders"
import { PartOrderDetail } from "~/views/PartOrders/PartOrderDetail"
import { Invoices } from "~/views/Service/Invoices"
import MachineMilestoneGroup from "~/views/MachineMilestone/MachineMilestoneGroup"
import MachineMilestone from "~/views/MachineMilestone/MachineMilestone"
import MilestoneDetail from "~/views/MachineMilestone/MilestoneDetail"
import { PriceOverrides } from "~/views/Service/PriceOverrides"
import { PricingAdjustmentRequests } from "~/views/Service/PricingAdjustmentRequests/PricingAdjustmentRequests"
import { PricingAdjustmentRequestDetail } from "~/views/Service/PricingAdjustmentRequests/PricingAdjustmentRequestDetail"

// Finance
import { LaborPricing } from "~/views/Finance/LaborPricing"
import { LaborPricingDetail } from "~/views/Finance/LaborPricingDetail"
import WashCountSubmission from "~/views/Finance/WashCountSubmission/WashCountSubmission"
import PricingMatrices from "~/views/Finance/PricingMatrix/PricingMatrices"
import PartGroups from "~/views/Finance/PartGroups/PartGroups"
import { FreightPricing } from "~/views/Finance/FreightPricing"

// Reports
import Reports from "~/views/Reports/Reports"
import InfoRefDocumentsContainer from "~/views/InfoRef/DocumentsContainer"
import InfoRefDocumentDetailContainer from "~/views/InfoRef/DocumentDetailContainer"

// Inventory
import LocationManagement from "~/views/Inventory/LocationManagement/Locations"
import PartManagement from "~/views/Inventory/PartManagement/PartManagement"
import { PartCrossReferences } from "~/views/Inventory/PartCrossReferences"
import { PurchaseOrders } from "~/views/PurchaseOrders/PurchaseOrders/PurchaseOrders"
import { PurchaseOrderDetail } from "~/views/PurchaseOrders/PurchaseOrderDetail"
import { InventoryTransaction } from "~/views/Inventory/InventoryTransaction"
import VendorManagement from "~/views/Inventory/VendorManagement/VendorManagement"
import { ChemicalPourDown } from "~/views/Inventory/ChemicalPourDown"
import QrCodeMaker from "~/views/Inventory/QrCodeMaker/QrCodeMaker"
import { ManufacturedParts } from "~/views/Inventory/ManufacturedParts"
import { PhysicalInventory } from "~/views/Inventory/PhysicalInventory/PhysicalInventory"
import { InventoryDetail } from "~/views/Inventory/PhysicalInventory/InventoryDetail"

// Customer
import { CustomerManagement } from "~/views/Customer/CustomerManagement/CustomerManagement"
import { CustomerDetail } from "~/views/Customer/CustomerManagement/CustomerDetail"
import Contracts from "~/views/Customer/Contracts/Contracts"
import ContractDetail from "~/views/Customer/Contracts/ContractDetail/ContractDetail"
import CustomerRoutes from "~/views/Customer/CustomerRoute/CustomerRoutes"
import RouteSchedule from "~/views/Customer/CustomerRoute/RouteSchedule"
import CreateRoute from "~/views/Customer/CustomerRoute/CreateRoute"
import RouteDetail from "~/views/Customer/CustomerRoute/RouteDetails"
import { Equipment } from "~/views/Customer/Equipment"
import MachineTypes from "~/views/MachineTypes/MachineTypes"
import MachineTypeDetail from "~/views/MachineTypes/MachineTypeDetail"
import MachinePhaseDetail from "~/views/MachineTypes/MachinePhaseDetail"
import { CustomerChemicals, CustomerChemicalsDetail } from "~/views/Customer/CustomerChemicals"
import { EnterprisesAndOrganizations } from "~/views/Customer/EnterprisesAndOrganizations"

// Marketing
import MarketingMaterialsContainer from "~/views/Marketing/MarketingMaterialsContainer"
import MarketingMaterialsUploadContainer from "~/views/Marketing/MarketingMaterialsUploadContainer"
import MarketingMaterialDetailContainer from "~/views/Marketing/MarketingMaterialDetailContainer"
import { default as MarketingViewCartContainer } from "~/views/Marketing/ViewCartContainer"
import { default as MarketingCheckoutContainer } from "~/views/Marketing/CheckoutContainer"

// Ticketing
import CreateTicketContainer from "~/views/Ticketing/CreateTicketContainer"
import TicketManagement from "~/views/Ticketing/TicketManagement"
import TicketingOverviewContainer from "~/views/Ticketing/TicketingOverviewContainer"
import TicketingUserManagementContainer from "~/views/Ticketing/UserManagement/TicketingUserManagementContainer"
import TypeSubtypeOverview from "~/views/Ticketing/TypeManagement/TypeSubtypeOverview"
import TicketingDashboardContainer from "~/views/Ticketing/TicketingDashboardContainer"
import TechSupportTicketContainer from "~/views/Ticketing/TechSupport/TechSupportTicketContainer"

// E-Commerce
import PosParts from "~/views/PointOfSale/Products/PosParts"
import PosPart from "~/views/PointOfSale/Products/PosPart"
import Catalogs from "~/views/PointOfSale/Catalogs/Catalogs"
import CustomerUsers from "~/views/PointOfSale/Users/CustomerUsers"
import CustomerUser from "~/views/PointOfSale/Users/CustomerUser"
import QuickOrders from "~/views/PointOfSale/QuickOrders/QuickOrders"
import BrandCards from "~/views/PointOfSale/BrandCards/BrandCards"
import Carousel from "~/views/PointOfSale/Carousel/Carousel"
import CarouselItem from "~/views/PointOfSale/Carousel/CarouselItem"
import BrandCard from "~/views/PointOfSale/BrandCards/BrandCard"

// Connectivity
import { ConnectedDevices } from "~/views/Connectivity/ConnectedDevices"
import { MachineDetail } from "~/views/Connectivity/MachineDetail"
import { VacuumDetail } from "~/views/Connectivity/VacuumDetail"
import { WashCounterDetail } from "~/views/Connectivity/WashCounterDetail"

// User Mgt
import UserDetailsContainer from "~/views/Users/UserDetailsContainer"
import MyProfileContainer from "~/views/Users/MyProfileContainer"
import UserSchedule from "~/views/Users/UserSchedule/UserSchedule"
import TechAssignmentGroups from "~/views/Users/TechAssignment/TechAssignmentGroups"
import TechAssignmentDetailContainer from "~/views/Users/TechAssignment/TechAssignmentDetailContainer"
import OffHoursContainer from "~/views/Users/OffHours/OffHoursContainer"
import UserManagement from "~/views/Users/UserManagement/UserManagement"
import UserProfile from "~/views/Users/UserManagement/UserProfile"

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard"
import Apps from "@material-ui/icons/Apps"
import PersonAdd from "@material-ui/icons/PersonAdd"
import People from "@material-ui/icons/People"
import Build from "@material-ui/icons/Build"
import Collections from "@material-ui/icons/Collections"
import Videocam from "@material-ui/icons/Videocam"
import ShoppingCart from "@material-ui/icons/ShoppingCart"
import AddCircle from "@material-ui/icons/AddCircle"
import BarChart from "@material-ui/icons/BarChart"
import AttachMoney from "@material-ui/icons/AttachMoney"
import VerticalSplit from "@material-ui/icons/VerticalSplit"
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber"
import AccessPoint from "./icons/AccessPoint"
import { ShoppingCartRounded } from "@material-ui/icons"
import ChemicalTechAssignmentDetailContainer from "~/views/Users/TechAssignment/ChemicalTechAssignmentDetailContainer"

const dashRoutes = [
	{
		path: "/dashboard",
		name: "Dashboard",
		icon: DashboardIcon,
		component: Dashboard,
	},
	{
		collapse: true,
		path: "/service",
		name: "Service",
		state: "openService",
		icon: Build,
		views: [
			{
				path: "/service-requests/dashboard",
				name: "Service Requests",
				restrictTo: APPLICATIONS.CreateDispatch,
				component: ServiceRequestsDashboardContainer,
			},
			{
				path: "/service/dispatches",
				name: "Dispatches",
				restrictTo: APPLICATIONS.CreateDispatch,
				component: Dispatches,
				exact: true,
			},
			{
				path: "/service/dispatches/:id",
				name: "Dispatch Detail",
				restrictTo: APPLICATIONS.CreateDispatch,
				component: DispatchDetail,
				hidden: true,
				exact: true,
			},
			{
				exact: true,
				path: "/quotes",
				name: "Quotes",
				restrictTo: APPLICATIONS.ERP,
				component: Quotes,
			},
			{
				hidden: true,
				path: "/quotes/:quoteId",
				name: "Quote Details",
				restrictTo: APPLICATIONS.ERP,
				component: QuoteDetail,
			},
			{
				exact: true,
				path: "/part-orders",
				name: "Part Orders",
				restrictTo: APPLICATIONS.PartsOrder,
				component: PartOrders,
			},
			{
				hidden: true,
				path: "/part-orders/:partOrderId",
				name: "Part Order Detail",
				restrictTo: APPLICATIONS.PartsOrder,
				component: PartOrderDetail,
			},
			{
				path: "/service/invoices",
				name: "Invoices",
				restrictTo: [APPLICATIONS.DeliveryOrder, APPLICATIONS.InvoicePrint],
				component: Invoices,
			},
			{
				exact: true,
				hidden: true,
				path: "/machine-milestone/groups",
				name: "Machine Milestones",
				restrictTo: APPLICATIONS.MachineMilestone,
				component: MachineMilestoneGroup,
			},
			{
				hidden: true,
				path: "/machine-milestone/groups/:id",
				name: "Machine Milestone Group",
				restrictTo: APPLICATIONS.MachineMilestone,
				component: MachineMilestone,
			},
			{
				hidden: true,
				path: "/machine-milestone/milestone/:id",
				name: "Machine Milestone",
				restrictTo: APPLICATIONS.MachineMilestone,
				component: MilestoneDetail,
			},
			{
				path: "/service/price-overrides",
				name: "Price Overrides",
				restrictTo: APPLICATIONS.PartsOrder,
				component: PriceOverrides,
			},
			{
				path: "/service/price-adjustment-requests",
				name: "Pricing Adjustment Requests",
				exact: true,
				restrictTo: APPLICATIONS.PriceAdjustmentRequests,
				component: PricingAdjustmentRequests,
			},
			{
				path: "/service/price-adjustment-requests/:id",
				name: "Pricing Adjustment Request Detail",
				hidden: true,
				exact: true,
				restrictTo: APPLICATIONS.PriceAdjustmentRequests,
				component: PricingAdjustmentRequestDetail,
			},
		],
	},
	{
		collapse: true,
		path: "/finance",
		name: "Finance",
		state: "finance",
		icon: AttachMoney,
		views: [
			{
				path: "/finance/labor-pricing",
				name: "Labor Pricing",
				exact: true,
				restrictTo: APPLICATIONS.LaborRateUser,
				component: LaborPricing,
			},
			{
				path: "/finance/labor-pricing/:id",
				name: "Labor Pricing Details",
				exact: true,
				hidden: true,
				restrictTo: APPLICATIONS.LaborRateUser,
				component: LaborPricingDetail,
			},
			{
				path: "/wash-count-submission",
				name: "Submit Wash Counts",
				component: WashCountSubmission,
				restrictTo: APPLICATIONS.WashCountSubmission,
			},
			{
				path: "/finance/matrix-pricing",
				name: "Matrix Pricing",
				restrictTo: [APPLICATIONS.PartDiscountAdmin, APPLICATIONS.CustomerManagement],
				component: PricingMatrices,
			},
			{
				path: "/finance/part-group-pricing",
				name: "Part Group Pricing",
				restrictTo: [APPLICATIONS.PartDiscountAdmin, APPLICATIONS.CustomerManagement],
				component: PartGroups,
			},
			{
				path: "/finance/freight",
				name: "Freight Pricing",
				component: FreightPricing,
				restrictTo: [
					APPLICATIONS.FreightRatesAdmin,
					APPLICATIONS.CustomerFreightExceptions,
					APPLICATIONS.FreightPriceOverride,
					APPLICATIONS.CustomerManagementReportsAdmin,
					APPLICATIONS.FreightPricingViewer,
				],
			},
		],
	},
	{
		path: "/reports",
		name: "Reports",
		short: "Reports",
		icon: BarChart,
		restrictTo: APPLICATIONS.CustomReports,
		component: Reports,
	},
	{
		hidden: true,
		path: "/info-ref/documents/:id",
		name: "Info Ref Document Details",
		breadcrumbRegex: /^\/info-ref\/documents\/\d+[/#?]?/,
		short: "Preview",
		icon: Videocam,
		restrictTo: [APPLICATIONS.InfoRefAdmin, APPLICATIONS.InfoRefViewer],
		component: InfoRefDocumentDetailContainer,
	},
	{
		hidden: true,
		path: "/info-ref/create-document",
		name: "Create Info Ref Document",
		short: "Create",
		icon: AddCircle,
		restrictTo: APPLICATIONS.InfoRefAdmin,
		component: InfoRefDocumentDetailContainer,
	},
	{
		path: "/info-ref/documents",
		exact: true,
		name: "Info Ref",
		icon: VerticalSplit,
		restrictTo: [APPLICATIONS.InfoRefAdmin, APPLICATIONS.InfoRefViewer],
		component: InfoRefDocumentsContainer,
	},
	{
		collapse: true,
		path: "/inventory",
		exact: true,
		name: "Inventory",
		state: "openInventory",
		icon: Apps,
		views: [
			{
				exact: true,
				path: "/inventory/locations",
				name: "Location Management",
				restrictTo: APPLICATIONS.InventoryManagement,
				component: LocationManagement,
			},
			{
				exact: true,
				path: "/purchase-orders",
				name: "Purchase Orders",
				restrictTo: APPLICATIONS.PartsOrder,
				component: PurchaseOrders,
			},
			{
				hidden: true,
				path: "/purchase-orders/:purchaseOrderId",
				name: "Purchase Order Detail",
				restrictTo: APPLICATIONS.PartsOrder,
				component: PurchaseOrderDetail,
			},
			{
				exact: true,
				path: "/inventory/part-management",
				name: "Part Management",
				restrictTo: APPLICATIONS.InventoryManagement,
				component: PartManagement,
			},
			{
				exact: true,
				path: "/inventory/part-cross-references",
				name: "Part Cross References",
				restrictTo: APPLICATIONS.CrossRefManagement,
				component: PartCrossReferences,
			},
			{
				exact: true,
				path: "/inventory/manufactured-parts",
				name: "Manufactured Parts",
				restrictTo: APPLICATIONS.ManufacturedParts,
				component: ManufacturedParts,
			},
			{
				exact: true,
				path: "/inventory/physical-inventory",
				name: "Physical Inventory",
				restrictTo: APPLICATIONS.PhysicalInventory,
				component: PhysicalInventory,
			},
			{
				exact: true,
				hidden: true,
				path: "/inventory/physical-inventory/:id",
				name: "Physical Inventory",
				restrictTo: APPLICATIONS.PhysicalInventory,
				component: InventoryDetail,
			},
			{
				path: "/inventory-transaction/",
				name: "Inventory Transaction",
				restrictTo: APPLICATIONS.InventoryManagement,
				component: InventoryTransaction,
			},
			{
				path: "/vendor-management/",
				name: "Supplier Management",
				component: VendorManagement,
			},
			{
				path: "/chemical-pour-down/",
				name: "Chemical Pour Down",
				restrictTo: APPLICATIONS.PourDown,
				component: ChemicalPourDown,
			},
			{
				path: "/qr-code-maker/",
				name: "Create QR Code",
				component: QrCodeMaker,
			},
		],
	},
	{
		collapse: true,
		path: "/ticketing",
		name: "Ticketing",
		state: "openTicketing",
		restrictTo: [
			APPLICATIONS.TicketingUser,
			APPLICATIONS.TicketingAdmin,
			APPLICATIONS.TicketingTechSupportUser,
		],
		icon: ConfirmationNumberIcon,
		views: [
			{
				path: "/ticketing/dashboard",
				name: "Dashboard",
				short: "Dashboard",
				icon: DashboardIcon,
				restrictTo: [
					APPLICATIONS.TicketingAdmin,
					APPLICATIONS.TicketingUser,
					APPLICATIONS.TicketingTechSupportUser,
				],
				component: TicketingDashboardContainer,
			},
			{
				path: "/ticketing/management",
				name: "Ticket Management",
				short: "Ticket Management",
				icon: ConfirmationNumberIcon,
				restrictTo: [
					APPLICATIONS.TicketingUser,
					APPLICATIONS.TicketingAdmin,
					APPLICATIONS.TicketingTechSupportUser,
				],
				component: TicketManagement,
			},
			{
				path: "/ticketing/user-management",
				name: "User Management",
				short: "User Management",
				restrictTo: [APPLICATIONS.TicketingAdmin],
				component: TicketingUserManagementContainer,
			},
			{
				path: "/ticketing/type-management",
				name: "Type/Subtype Management",
				short: "Type/Subtype Management",
				restrictTo: [APPLICATIONS.TicketingAdmin],
				component: TypeSubtypeOverview,
			},
			{
				path: "/ticketing/tech-support",
				name: "Tech Support",
				short: "Tech Support",
				restrictTo: [APPLICATIONS.TicketingTechSupportUser],
				component: TechSupportTicketContainer,
			},
			{
				hidden: true,
				path: "/ticketing/create",
				name: "Create Ticket",
				short: "Create Ticket",
				restrictTo: [
					APPLICATIONS.TicketingUser,
					APPLICATIONS.TicketingAdmin,
					APPLICATIONS.TicketingTechSupportUser,
				],
				component: CreateTicketContainer,
			},
			{
				hidden: true,
				path: "/ticketing/detail/:id",
				breadcrumbRegex: /^\/location-details\/\d+[/#?]?/,
				name: "Ticket Details",
				short: "Details",
				restrictTo: [
					APPLICATIONS.TicketingUser,
					APPLICATIONS.TicketingAdmin,
					APPLICATIONS.TicketingTechSupportUser,
				],
				component: TicketingOverviewContainer,
			},
		],
	},
	{
		collapse: true,
		path: "/e-commerce",
		name: "E-Commerce",
		state: "openECommerce",
		icon: ShoppingCartRounded,
		restrictTo: [APPLICATIONS.PointOfSaleAdmin],
		views: [
			{
				path: "/pos-products",
				exact: true,
				name: "Products",
				short: "POS Products",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: PosParts,
			},
			{
				path: "/pos-products/product/:id?",
				exact: true,
				hidden: true,
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: PosPart,
			},
			{
				path: "/carousel",
				exact: true,
				name: "Carousel",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: Carousel,
			},
			{
				path: "/carousel/item/:id?",
				exact: true,
				hidden: true,
				name: "Carousel",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: CarouselItem,
			},
			{
				path: "/pos-catalogs",
				exact: true,
				name: "Customer Catalogs",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: Catalogs,
			},
			{
				path: "/pos-users/:id",
				exact: true,
				hidden: true,
				name: "Customer User Details",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: CustomerUser,
			},
			{
				path: "/pos-quick-orders",
				exact: true,
				name: "Quick Orders",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: QuickOrders,
			},
			{
				path: "/pos-brand-cards",
				exact: true,
				name: "Homepage Brand Cards",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: BrandCards,
			},
			{
				path: "/pos-brand-cards/card/:id?",
				exact: true,
				hidden: true,
				name: "Homepage Brand Cards",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: BrandCard,
			},
			{
				path: "/pos-users",
				exact: true,
				name: "Customer User Accounts",
				restrictTo: APPLICATIONS.PointOfSaleAdmin,
				component: CustomerUsers,
			},
		],
	},
	{
		collapse: true,
		path: "/customer",
		name: "Customers",
		state: "openCustomer",
		icon: PersonAdd,
		views: [
			{
				exact: true,
				path: "/customer-management",
				name: "Customer Management",
				restrictTo: APPLICATIONS.CustomerManagement,
				component: CustomerManagement,
			},
			{
				exact: true,
				hidden: true,
				path: "/customer-management/:customerId",
				restrictTo: APPLICATIONS.CustomerManagement,
				component: CustomerDetail,
			},
			{
				exact: true,
				path: "/customers/equipment",
				name: "Customer Equipment",
				restrictTo: APPLICATIONS.EquipmentManagement,
				component: Equipment,
			},
			{
				exact: true,
				path: "/contracts",
				name: "Contract Management",
				restrictTo: [APPLICATIONS.ContractViewer, APPLICATIONS.ContractCreator],
				component: Contracts,
			},
			{
				hidden: true,
				path: "/contracts/:id",
				name: "Contract Details",
				restrictTo: [APPLICATIONS.ContractViewer, APPLICATIONS.ContractCreator],
				component: ContractDetail,
			},
			{
				exact: true,
				path: "/machine-types",
				name: "Machine Types and Phases",
				restrictTo: [
					APPLICATIONS.ChemMachineTypeAdmin,
					APPLICATIONS.ChemMachineTypeViewer,
				],
				component: MachineTypes,
			},
			{
				exact: true,
				hidden: true,
				path: "/machine-types/:machine_type_id",
				name: "Chemical Machine Type",
				restrictTo: [
					APPLICATIONS.ChemMachineTypeAdmin,
					APPLICATIONS.ChemMachineTypeViewer,
				],
				component: MachineTypeDetail,
			},
			{
				exact: true,
				hidden: true,
				path: "/machine-types/:machine_type_id/phases/:phase_id",
				name: "Machine Phase",
				restrictTo: [
					APPLICATIONS.ChemMachineTypeAdmin,
					APPLICATIONS.ChemMachineTypeViewer,
				],
				component: MachinePhaseDetail,
			},
			{
				exact: true,
				path: "/customer/routes",
				name: "Customer Routes",
				short: "Routes",
				restrictTo: APPLICATIONS.CustomerManagement,
				component: CustomerRoutes,
			},
			{
				hidden: true,
				path: "/customer/create-route",
				name: "Route Create",
				short: "Create",
				restrictTo: APPLICATIONS.CustomerManagement,
				component: CreateRoute,
			},
			{
				hidden: true,
				path: "/customer/routes/:route_id",
				name: "Route Details",
				restrictTo: APPLICATIONS.CustomerManagement,
				component: RouteDetail,
			},
			{
				exact: true,
				path: "/customer/schedule-route",
				name: "Route Schedule",
				short: "Routes",
				restrictTo: APPLICATIONS.CustomerManagement,
				component: RouteSchedule,
			},
			{
				path: "/customers/customer-chemicals",
				name: "Customer Chemicals",
				restrictTo: APPLICATIONS.ChemicalService,
				component: CustomerChemicals,
				exact: true,
			},
			{
				path: "/customers/customer-chemicals/:customerId",
				restrictTo: APPLICATIONS.ChemicalService,
				component: CustomerChemicalsDetail,
				hidden: true,
				exact: true,
			},
			{
				path: "/customers/enterprises-and-organizations",
				name: "Enterprises & Organizations",
				restrictTo: APPLICATIONS.CustomerHierarchy,
				component: EnterprisesAndOrganizations,
				exact: true,
			},
		],
	},
	{
		collapse: true,
		path: "/marketing",
		name: "Marketing",
		state: "openMarketing",
		icon: Collections,
		views: [
			{
				path: "/marketing/materials",
				name: "Marketing Materials",
				breadcrumbRegex: /^\/marketing\/materials/,
				short: "Materials",
				icon: Collections,
				restrictTo: [
					APPLICATIONS.MarketingMaterialAdmin,
					APPLICATIONS.MarketingMaterialUser,
				],
				component: MarketingMaterialsContainer,
			},
			{
				hidden: true,
				path: "/marketing/preview/:id",
				name: "Marketing Materials - Details",
				breadcrumbRegex: /^\/marketing\/preview\/\d+[/#?]?/,
				short: "Preview",
				icon: Videocam,
				restrictTo: [
					APPLICATIONS.MarketingMaterialAdmin,
					APPLICATIONS.MarketingMaterialUser,
				],
				component: MarketingMaterialDetailContainer,
			},
			{
				path: "/marketing/upload",
				name: "Upload Document",
				short: "Upload",
				icon: AddCircle,
				restrictTo: APPLICATIONS.MarketingMaterialAdmin,
				component: MarketingMaterialsUploadContainer,
			},
			{
				hidden: true,
				path: "/marketing/cart",
				name: "Marketing Materials - Cart",
				short: "Shopping Cart",
				icon: ShoppingCart,
				restrictTo: [
					APPLICATIONS.MarketingMaterialAdmin,
					APPLICATIONS.MarketingMaterialUser,
				],
				component: MarketingViewCartContainer,
			},
			{
				hidden: true,
				path: "/marketing/checkout",
				name: "Marketing Materials - Checkout",
				short: "Checkout",
				restrictTo: [
					APPLICATIONS.MarketingMaterialAdmin,
					APPLICATIONS.MarketingMaterialUser,
				],
				component: MarketingCheckoutContainer,
			},
		],
	},
	{
		collapse: true,
		path: "/connectivity",
		exact: true,
		name: "Connectivity",
		state: "openConnectivity",
		icon: AccessPoint,
		views: [
			{
				path: "/connectivity/connected-devices",
				exact: true,
				name: "Connected Devices",
				restrictTo: [
					APPLICATIONS.MachineAdmin,
					APPLICATIONS.VacuumAdmin,
					APPLICATIONS.WashCounterAdmin,
				],
				component: ConnectedDevices,
			},
			{
				path: "/connectivity/connected-devices/machines/:id",
				hidden: true,
				exact: true,
				name: "Connected Machine Detail",
				restrictTo: APPLICATIONS.MachineAdmin,
				component: MachineDetail,
			},
			{
				path: "/connectivity/connected-devices/vacuums/:id",
				hidden: true,
				exact: true,
				name: "Connected Vacuum Detail",
				restrictTo: APPLICATIONS.VacuumAdmin,
				component: VacuumDetail,
			},
			{
				path: "/connectivity/connected-devices/wash-counters/:id",
				hidden: true,
				exact: true,
				name: "WashCounter Detail",
				restrictTo: APPLICATIONS.WashCounterAdmin,
				component: WashCounterDetail,
			},
		],
	},
	{
		collapse: true,
		path: "/surveys",
		name: "User Management",
		state: "openUserManagement",
		icon: People,
		views: [
			{
				path: "/user-management/",
				name: "User Management",
				exact: true,
				restrictTo: [APPLICATIONS.UserManagement],
				component: UserManagement,
			},
			{
				path: "/user-management/:userId",
				name: "User Profile",
				exact: true,
				hidden: true,
				restrictTo: [APPLICATIONS.UserManagement],
				component: UserProfile,
			},
			{
				exact: true,
				path: "/technician-assignment/",
				name: "Tech Assignment",
				restrictTo: [APPLICATIONS.UserManagement, APPLICATIONS.TSMUserManagement],
				component: TechAssignmentGroups,
			},
			{
				hidden: true,
				path: "/technician-assignment/:tech_ass_id",
				name: "Assignment Detail",
				short: "Users",
				restrictTo: [APPLICATIONS.UserManagement, APPLICATIONS.TSMUserManagement],
				component: TechAssignmentDetailContainer,
			},
			{
				hidden: true,
				path: "/chemical-technician-assignment/:tech_ass_id",
				name: "Chemical Technician Assignment Detail",
				short: "Chem Tech Assignment",
				restrictTo: [APPLICATIONS.UserManagement, APPLICATIONS.TSMUserManagement],
				component: ChemicalTechAssignmentDetailContainer,
			},
			{
				exact: true,
				path: "/user/schedule",
				name: "User Schedules",
				short: "Schedule",
				restrictTo: APPLICATIONS.UserSchedule,
				component: UserSchedule,
			},
			{
				exact: true,
				path: "/off-hours/",
				name: "Off Hours",
				short: "Off Hours",
				restrictTo: [APPLICATIONS.UserManagement, APPLICATIONS.TSMUserManagement],
				component: OffHoursContainer,
			},
		],
	},
	{
		collapse: true,
		hidden: true,
		path: "/hidden",
		name: "Hidden Items",
		icon: Collections,
		views: [
			{
				hidden: true,
				path: "/users/:id",
				breadcrumbRegex: /^\/users\/\d+[/#?]?/,
				name: "User Profile",
				restrictTo: [APPLICATIONS.UserManagement, APPLICATIONS.TSMUserManagement],
				component: UserDetailsContainer,
			},
			{
				hidden: true,
				path: "/profile",
				name: "My Profile",
				component: MyProfileContainer,
			},
		],
	},

	{ redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" },
]
export default dashRoutes
