import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { APPLICATION } from "../auth"
import { Report } from "../reports"

export enum SecurityUrlPaths {
	ApplicationAssignments = "application_assignments",
	ApplicationsList = "applications_list",
	AssignUserApplications = "assign_user_applications",
	Customers = "customers",
	CustomerUserLocationsUpdate = "customer_user_locations_update",
	CreateCustomer = "create_customer",
	Enterprises = "enterprises",
	LocationsUserUpdate = "locations_user_update",
	Organizations = "organizations",
	Regions = "regions",
	ResetPasswordToken = "reset_password_token",
	SalesAssignments = "sales_assignments",
	Security = "security",
	Territories = "territories",
	UserGroups = "user_groups",
	UserLocations = "user_locations",
	UserProfiles = "user_profiles",
	Users = "users",
}

export interface Application {
	id: string
	description: APPLICATION
}

export interface AssignedApplication {
	id: string
	application: Application
}

export interface ProfileApplicationAssignment {
	id: number
	applications: AssignedApplication[]
	description: string
	isDefault: boolean
}

export interface ApplicationAssignment {
	id: string
	applications: Application[]
	description: string
	isDefault: boolean
}

export interface AssignUserApplicationsPost {
	userId: string
	applicationAssignmentId: string | null
	applications: string[] | null
}

export interface AssignedReport {
	id: string
	report: Report
}

export interface ProfileReportAssignment {
	id: number
	description: string
	isDefault: boolean
	reports: AssignedReport[]
}

export interface ReportAssignment {
	id: string
	description: string
	isDefault: boolean
	reports: Report[]
}

export interface UserProfile {
	id: number
	applicationsGroup: ProfileApplicationAssignment | null
	defaultTerritory: TerritoryMinimal | null
	effectiveDate: string
	email: string
	employeeNumber: string | null
	endDate: string
	firstName: string
	isActiveDirectoryUser: boolean
	isCustomer: boolean
	isCustomerSuperuser: boolean
	isGuest: boolean
	lastLogin: string | null
	lastName: string
	middleInitial: string | null
	mobile: string | null
	poRequired: boolean | null
	reportsGroup: ProfileReportAssignment | null
	siteCount: number // This is whether or not this user has any actual billable customers attached to them. Important!
	textEmail: string | null
	verisaeUsername: string | null
	techCustomer: {
		customerId: string
		customerNumber: string
	}
}

export interface CreateCustomerFromUserPost {
	address1: string
	address2: string
	address3: string
	city: string
	phoneNumber: string
	state: string
	postalCode: string
}

export interface UserProfileQueryParams extends SearchQueryParams, OrderingQueryParams {
	isCustomer: boolean | null
	isGuest: boolean | null
	noSites: boolean | null
}

export interface CreateUserProfilePost {
	employeeNumber: string | null
	firstName: string
	lastName: string
	email: string
	mobile: string | null
	verisaeUsername: string | null
	effectiveDate: string
	reportAssignmentId: string | null
	applicationAssignmentId: string | null
	isCustomer: boolean
	groupIds?: string[]
}

export type UpdateUserProfilePatch = Partial<{
	applicationAssignmentId: string | null
	defaultTerritoryId: string | null
	effectiveDate: string | undefined
	email: string | undefined
	employeeNumber: string | null
	endDate: string | undefined
	firstName: string | undefined
	isCustomerSuperuser: boolean
	isGuest: boolean | null
	lastName: string | undefined
	mobile: string | null
	poRequired: boolean | null
	reportAssignmentId: string | null
	verisaeUsername: string | null
}>

export interface UserMinimal {
	id: string
	email: string
	name: string
	mobile: string | null // Can be an empty string
	endDate: string
	isActive?: boolean | null
}

export interface UserMinimalQueryParams extends SearchQueryParams {
	isCustomer: boolean | null
	isGuest: boolean | null
	isActive?: boolean | null
}

export interface TerritoryMinimal {
	id: string
	code: string
	description: string
	isDomestic: boolean | null
}

export interface RequestResetPasswordPost {
	email: string
}

export interface SetNewPasswordPost {
	password: string
}

export interface OrganizationMinimal {
	id: string
	name: string
}

export interface RegionMinimal {
	id: string
	code: string
	description: string
}

export interface EnterpriseMinimal {
	id: string
	name: string | null
}

export interface UserLocation {
	// These can be from either a territory or a customer.
	locationId: number
	locationName: string
	locationNumber: string

	// These are only there if it's a customer.
	customerAddress?: string
	customerCity?: string
	customerState?: string
	customerPostalCode?: string
}

export interface CustomerUserLocationsPost {
	addCustomerIds: string[] | null
	addBillToIds: string[] | null
	removeCustomerIds: string[] | null
}

export interface UserLocationPost {
	billToCustomers: boolean
	customers:
		| {
				locationId: string
				locationName: string
				locationNumber: string
		  }[]
		| null
	locations:
		| {
				_id: string // Yep
				description: string
				code: string
		  }[]
		| null
}

export interface UserAndEmailSerializer {
	id: string
	name: string
	email: string
}

/**
 * The a group that a user is associated with.
 */
export interface UserGroup {
	id: string
	code: string
	description: string
}

export interface UpdateUserGroupsPost {
	userId: string
	groupIds: string[]
}

export interface SalesAssignment {
	id: string
	salesType: {
		id: number
		description: string
	}
	salesRole: {
		id: string
		name: string
	} | null
	user: {
		id: string
		name: string
	}
	customer: {
		id: string
		name: string
		customerNumber: string
	}
}

export interface SalesAssignmentsQueryParams {
	user: string | null
	customer: string | null
}

export interface CreateSalesAssignmentPost {
	user: string
	customer: string
	salesType: string
	salesRole: string
}

/**
 * `TerritorySerializer` in /security/serializer.py
 */
export interface Territory {
	id: string
	code: string
	description: string
	region: RegionMinimal
}

export interface Enterprise {
	id: string
	createdOn: string | null
	isActive: boolean
	modifiedOn: string | null
	name: string
	organizationsCount: number
}

export interface CreateEnterprisePost {
	name: string
}

export type UpdateEnterprisePatch = Partial<{
	name: string
	isActive: boolean
}>

export interface Organization {
	id: string
	createdOn: string | null
	customersCount: number
	enterpriseId: number
	enterpriseName: string
	isActive: boolean
	modifiedOn: string | null
	name: string
}

export interface OrganizationQueryParams {
	enterprise: string | null
}

export interface CreateOrganizationPost {
	name: string
	enterprise: string
}

export type UpdateOrganizationPatch = Partial<{
	name: string
	enterprise: string
	isActive: boolean
}>

export interface OrganizationCustomer {
	id: string
	address1: string | null
	address2: string
	billTos: {
		id: string
		customerNumber: string
		name: string
	}[]
	city: string
	customerNumber: string
	name: string
	postalcode: string
	state: string
}

export type UpdateOrganizationCustomersPost = Partial<{
	addBillTo: string
	addShipTo: string
	removeShipTo: string
	removeBillTo: string
	addIncludesInactive: boolean
}>
