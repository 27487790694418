import { FC, useMemo } from "react"

import { css } from "@emotion/react"
import { useFormContext, useWatch } from "react-hook-form"

import { Paragraph } from "@ncs/web-legos"

import { CarouselImageForm } from "./carousel-utils"

export interface CarouselImagePreviewProps {}

export const CarouselImagesPreview: FC<CarouselImagePreviewProps> = () => {
	// Use `useWatch` for performance reasons.
	const [text] = useWatch<CarouselImageForm>({
		name: ["text"],
	})
	// Use `watch` for typing reasons.
	const { watch } = useFormContext<CarouselImageForm>()
	const [mobileImageUrl, tabletImageUrl, desktopImageUrl, tvImageUrl] = watch([
		"mobileImageUrl",
		"tabletImageUrl",
		"desktopImageUrl",
		"tvImageUrl",
	])

	const carouselDesktopCss = useMemo(() => {
		return css`
			margin: 0 -1.5rem;
			margin-bottom: 1rem;
			overflow: hidden;
			background: black;
			> div {
				height: 10rem;
				background-image: url(${desktopImageUrl});
				background-size: cover;
				background-position: center center;
				transition-duration: 200ms;
				transition-timing-function: ease-out;
				transition-property: transform opacity;
				&:hover {
					opacity: 0.9;
					transform: scale(1.05);
				}
			}
		`
	}, [desktopImageUrl])

	const carouselMobileCss = useMemo(() => {
		return css`
			margin: 0 -1.5rem;
			margin-bottom: 1rem;
			overflow: hidden;
			background: black;
			> div {
				height: 10rem;
				background-image: url(${mobileImageUrl});
				background-size: cover;
				background-position: center center;
				transition-duration: 200ms;
				transition-timing-function: ease-out;
				transition-property: transform opacity;
				&:hover {
					opacity: 0.9;
					transform: scale(1.05);
				}
			}
		`
	}, [mobileImageUrl])

	const carouselTabletCss = useMemo(() => {
		return css`
			margin: 0 -1.5rem;
			margin-bottom: 1rem;
			overflow: hidden;
			background: black;
			> div {
				height: 10rem;
				background-image: url(${tabletImageUrl});
				background-size: cover;
				background-position: center center;
				transition-duration: 200ms;
				transition-timing-function: ease-out;
				transition-property: transform opacity;
				&:hover {
					opacity: 0.9;
					transform: scale(1.05);
				}
			}
		`
	}, [tabletImageUrl])

	const carouselTvCss = useMemo(() => {
		return css`
			margin: 0 -1.5rem;
			margin-bottom: 1rem;
			overflow: hidden;
			background: black;
			> div {
				height: 10rem;
				background-image: url(${tvImageUrl});
				background-size: cover;
				background-position: center center;
				transition-duration: 200ms;
				transition-timing-function: ease-out;
				transition-property: transform opacity;
				&:hover {
					opacity: 0.9;
					transform: scale(1.05);
				}
			}
		`
	}, [tvImageUrl])

	return (
		<div css={containerCss}>
			<div css={carouselDesktopCss}>
				{desktopImageUrl ?
					<div />
				:	<Paragraph color="white" small textAlign="center" py={4}>
						(Carousel desktop image will show here)
					</Paragraph>
				}
			</div>
			<div css={carouselMobileCss}>
				{mobileImageUrl ?
					<div />
				:	<Paragraph color="white" small textAlign="center" py={4}>
						(Carousel mobile image will show here)
					</Paragraph>
				}
			</div>
			<div css={carouselTabletCss}>
				{tabletImageUrl ?
					<div />
				:	<Paragraph color="white" small textAlign="center" py={4}>
						(Carousel tablet image will show here)
					</Paragraph>
				}
			</div>
			<div css={carouselTvCss}>
				{tvImageUrl ?
					<div />
				:	<Paragraph color="white" small textAlign="center" py={4}>
						(Carousel TV image will show here)
					</Paragraph>
				}
			</div>
			<Paragraph textAlign="center" my={1.25} color={!text ? "secondary" : undefined}>
				{text || "(Tagline will show here)"}
			</Paragraph>
		</div>
	)
}

const containerCss = css`
	max-width: 25rem;
	padding: 1.5rem;
	border-radius: 0 25px 0 25px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
`
