import React from "react"
import PropTypes from "prop-types"
import {
	createStyles,
	FormControl,
	FormControlLabel,
	FormControlProps,
	makeStyles,
	Radio,
	RadioGroup,
	RadioGroupProps,
} from "@material-ui/core"
import { getAccessorValue } from "../../util/accessors"

const useStyles = makeStyles(({ palette }) =>
	createStyles({
		radioGroupFormControl: {
			marginBottom: "2rem",
			// Override Material Dashboard Pro's gray labels, otherwise available options look disabled.
			"& .MuiFormControlLabel-label:not(.Mui-disabled)": {
				color: palette.text.primary,
			},
		},
	})
)

export interface CustomRadioGroupEcommProps {
	name: string
	options: unknown[]
	onChange: RadioGroupProps["onChange"]
	value?: string
	formControlClasses?: string[]
	formControlProps?: FormControlProps
	valueAccessor?: string | ((option: unknown) => string)
	labelAccessor?: string | ((option: unknown) => string)
	disabledAccessor?: string | ((option: unknown) => boolean)
	labelText?: string
	fullWidth?: boolean
	isError?: boolean
	row?: boolean
}

const CustomRadioGroupEcomm: React.FC<CustomRadioGroupEcommProps> = ({
	fullWidth = true,
	formControlClasses = [],
	labelText,
	formControlProps,
	isError,
	name,
	onChange,
	value,
	options,
	labelAccessor = "label",
	valueAccessor = "value",
	disabledAccessor = "disabled",
	row,
	...rest
}) => {
	const classes = useStyles()

	if (options.length === 0) {
		return null
	}

	return (
		<FormControl
			fullWidth={fullWidth}
			className={[classes.radioGroupFormControl, ...formControlClasses].join(" ")}
			{...formControlProps}
			error={isError}
			{...rest}
		>
			{labelText && <p>{labelText}</p>}
			<RadioGroup
				name={name}
				aria-label={labelText}
				onChange={onChange}
				row={row}
				value={value}
			>
				{options.map((option) => (
					<FormControlLabel
						key={getAccessorValue(option, valueAccessor)}
						value={getAccessorValue(option, valueAccessor)}
						label={getAccessorValue(option, labelAccessor)}
						disabled={getAccessorValue(option, disabledAccessor)}
						control={<Radio color="primary" />}
					/>
				))}
			</RadioGroup>
		</FormControl>
	)
}

CustomRadioGroupEcomm.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	formControlClasses: PropTypes.arrayOf(PropTypes.string.isRequired),
	formControlProps: PropTypes.object,
	valueAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	labelAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	disabledAccessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
	labelText: PropTypes.string,
	fullWidth: PropTypes.bool,
	isError: PropTypes.bool,
	row: PropTypes.bool,
}

export default React.memo(CustomRadioGroupEcomm)
