import React from "react"

import { useTheme } from "@emotion/react"
import {
	Bar,
	BarChart as RechartBarChart,
	CartesianGrid,
	Legend,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"

import { PortalReport } from "@ncs/ncs-api"

import { formatNumber, pythonify } from "@ncs/ts-utils"

import { ExtendableChartProps } from "./charts-util"
import { ResponsiveChartContainer } from "./ResponsiveChartContainer"

export interface BarChartProps extends ExtendableChartProps {
	report: PortalReport
	keysToExclude?: string[]
}

export const BarChart: React.FC<BarChartProps> = ({
	report,
	keysToExclude = [],
	withLegend = true,
	colorArray,
	xAxisTickFormatter,
	yAxisTickFormatter,
	xAxisDomain,
	yAxisDomain,
	xAxisType,
	yAxisType,
	height,
}) => {
	const theme = useTheme()

	return (
		<ResponsiveChartContainer height={height}>
			<RechartBarChart data={report.data}>
				<XAxis
					dataKey={report.xKey}
					tickFormatter={xAxisTickFormatter}
					domain={xAxisDomain}
					type={xAxisType}
				/>
				<YAxis
					domain={yAxisDomain ?? ["dataMin", "dataMax"]}
					tickFormatter={yAxisTickFormatter || ((value) => formatNumber(value))}
					type={yAxisType}
				/>

				<CartesianGrid stroke={theme.palette.grey[200]} />

				<Tooltip />

				{withLegend && (
					<Legend
						align="right"
						layout="vertical"
						wrapperStyle={{
							paddingLeft: "2rem",
							paddingBottom: "1.5rem",
						}}
						formatter={(value) => {
							return report.dataKeys[pythonify(value)]
						}}
					/>
				)}

				{Object.keys(report.dataKeys)
					.filter((key) => !keysToExclude.includes(key))
					.map((key, i) => (
						<Bar
							key={key}
							dataKey={key}
							minPointSize={1}
							fill={
								colorArray ?
									colorArray[i] ??
									// If we run out of colors, start at the beginning of theme colors array.
									theme.palette.chartColors[i - colorArray.length]
								:	theme.palette.chartColors[i]
							}
						/>
					))}
			</RechartBarChart>
		</ResponsiveChartContainer>
	)
}
