import { useMemo } from "react"

import { Id, unpythonify } from "@ncs/ts-utils"

import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPatchMutationOptions,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import { LookupsUrlPaths } from "../lookups"
import {
	Application,
	ApplicationAssignment,
	AssignUserApplicationsPost,
	CreateCustomerFromUserPost,
	CreateEnterprisePost,
	CreateOrganizationPost,
	CreateSalesAssignmentPost,
	CreateUserProfilePost,
	CustomerUserLocationsPost,
	Enterprise,
	EnterpriseMinimal,
	Organization,
	OrganizationCustomer,
	OrganizationMinimal,
	OrganizationQueryParams,
	RegionMinimal,
	RequestResetPasswordPost,
	SalesAssignment,
	SalesAssignmentsQueryParams,
	SecurityUrlPaths,
	SetNewPasswordPost,
	TerritoryMinimal,
	UpdateEnterprisePatch,
	UpdateOrganizationCustomersPost,
	UpdateOrganizationPatch,
	UpdateUserGroupsPost,
	UpdateUserProfilePatch,
	UserGroup,
	UserLocation,
	UserLocationPost,
	UserMinimal,
	UserMinimalQueryParams,
	UserProfile,
	UserProfileQueryParams,
} from "./types"

export const useUser = (
	userId: string | null | undefined,
	options?: ApiGetQueryOptions<UserMinimal>
): ApiGetQuery<UserMinimal> => {
	return useGetRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Users, userId ?? ""], {
		...options,
		queryConfig: {
			enabled: !!userId,
			...options?.queryConfig,
		},
	})
}

export const useUsers = (
	options?: ApiInfiniteGetQueryOptions<UserMinimal, UserMinimalQueryParams>
): ApiInfiniteGetQuery<UserMinimal> => {
	return useInfiniteGetRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Users], options)
}

export const useUserProfile = (
	userId: string | null | undefined,
	options?: ApiGetQueryOptions<UserProfile>
): ApiGetQuery<UserProfile> => {
	return useGetRequest<UserProfile>(
		[SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles, userId ?? ""],
		{
			...options,
			queryConfig: {
				enabled: !!userId,
				...options?.queryConfig,
			},
		}
	)
}

export const useCreateCustomerFromUser = (
	userId: string
): ApiPostMutation<CreateCustomerFromUserPost> => {
	return usePostRequest(
		[
			SecurityUrlPaths.Security,
			SecurityUrlPaths.UserProfiles,
			userId,
			SecurityUrlPaths.CreateCustomer,
		],
		{
			keyToInvalidate: [SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles],
		}
	)
}

export const defaultUserProfilesQueryParams: UserProfileQueryParams = {
	search: null,
	ordering: null,
	isCustomer: null,
	isGuest: null,
	noSites: null,
}

export const useUserProfiles = (
	options?: ApiInfiniteGetQueryOptions<UserProfile, UserProfileQueryParams>
): ApiInfiniteGetQuery<UserProfile> => {
	return useInfiniteGetRequest(
		[SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles],
		options
	)
}

export const useNewCustomerUserProfiles = (
	options?: ApiInfiniteGetQueryOptions<UserProfile, UserProfileQueryParams>
): [UserProfile[], boolean] => {
	const { data, isLoading } = useInfiniteGetRequest<UserProfile, UserProfileQueryParams>(
		[SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles],
		{
			params: {
				isCustomer: true,
				isGuest: false,
			},
			pageSize: 9999,
			...options,
		}
	)

	const customers = useMemo(() => {
		return data.filter((user) => user.siteCount === 0)
	}, [data])

	return [customers, isLoading]
}

export const useCreateUserProfile = (): ApiPostMutation<
	CreateUserProfilePost,
	Id<UserProfile, number, "_id">
> => {
	return usePostRequest([SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles], {
		keyToInvalidate: [SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles],
	})
}

export const useUpdateUserProfile = (
	options?: ApiPatchMutationOptions<UpdateUserProfilePatch>
): ApiPatchMutation<UpdateUserProfilePatch> => {
	return usePatchRequest([SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles], {
		keyToInvalidate: [SecurityUrlPaths.Security, SecurityUrlPaths.UserProfiles],
		...options,
	})
}

export const useUserLocations = (
	userId: string | null | undefined
): ApiGetQuery<UserLocation[]> => {
	return useGetRequest(
		[SecurityUrlPaths.Security, SecurityUrlPaths.UserLocations, userId ?? ""],
		{
			queryConfig: {
				enabled: !!userId,
			},
		}
	)
}

export const useSecurityApplications = (): ApiGetQuery<Application[]> => {
	return useGetRequest([SecurityUrlPaths.Security, SecurityUrlPaths.ApplicationsList], {
		mapper: (items) => {
			return unpythonify(items).sort((a, b) => (a.description > b.description ? 1 : -1))
		},
	})
}

export const useApplicationAssignments = (
	defaultOnly?: boolean
): ApiGetQuery<ApplicationAssignment[]> => {
	return useGetRequest([SecurityUrlPaths.Security, SecurityUrlPaths.ApplicationAssignments], {
		params: {
			is_default: defaultOnly || undefined,
		},
	})
}

export const useAssignUserApplications = (): ApiPostMutation<
	AssignUserApplicationsPost,
	UserProfile
> => {
	return usePostRequest([SecurityUrlPaths.Security, SecurityUrlPaths.AssignUserApplications], {
		keyToInvalidate: SecurityUrlPaths.Security,
	})
}

export const useUpdateCustomerUserLocations = (
	userId: string
): ApiPostMutation<CustomerUserLocationsPost> => {
	return usePostRequest(
		[SecurityUrlPaths.Security, SecurityUrlPaths.CustomerUserLocationsUpdate, userId],
		{
			keyToInvalidate: [SecurityUrlPaths.Security, SecurityUrlPaths.UserLocations],
		}
	)
}

export const useUpdateUserLocations = (userId: string): ApiPostMutation<UserLocationPost> => {
	return usePostRequest(
		[SecurityUrlPaths.Security, SecurityUrlPaths.LocationsUserUpdate, userId],
		{
			keyToInvalidate: [SecurityUrlPaths.Security, SecurityUrlPaths.UserLocations],
		}
	)
}

export const useSendPasswordReset = (): ApiPostMutation<RequestResetPasswordPost> => {
	return usePostRequest([SecurityUrlPaths.Security, SecurityUrlPaths.ResetPasswordToken])
}

export const useTokenVerification = (
	token?: string | null,
	options?: ApiGetQueryOptions
): ApiGetQuery<unknown> => {
	return useGetRequest<unknown>(
		[SecurityUrlPaths.Security, SecurityUrlPaths.ResetPasswordToken, token ?? ""],
		{
			queryConfig: {
				retry: false,
				...options?.queryConfig,
				enabled: !!token && options?.queryConfig?.enabled,
			},
		}
	)
}

export const useSetNewPassword = (token?: string | null): ApiPostMutation<SetNewPasswordPost> => {
	return usePostRequest([
		SecurityUrlPaths.Security,
		SecurityUrlPaths.ResetPasswordToken,
		token ?? "",
	])
}

export const useTerritories = (): ApiGetQuery<TerritoryMinimal[]> => {
	return useGetRequest([SecurityUrlPaths.Territories])
}

export const useActiveOrganizations = (): ApiGetQuery<OrganizationMinimal[]> => {
	return useGetRequest([SecurityUrlPaths.Organizations])
}

export const useRegions = (): ApiGetQuery<RegionMinimal[]> => {
	return useGetRequest([SecurityUrlPaths.Regions])
}

export const useActiveEnterprises = (): ApiGetQuery<EnterpriseMinimal[]> => {
	return useGetRequest([SecurityUrlPaths.Enterprises])
}

export const useUserGroups = (userId: string): ApiGetQuery<UserGroup[]> => {
	return useGetRequest([SecurityUrlPaths.Security, SecurityUrlPaths.UserGroups], {
		params: {
			userId,
		},
	})
}

export const useUpdateUserGroups = (): ApiPostMutation<UpdateUserGroupsPost> => {
	return usePostRequest([SecurityUrlPaths.Security, SecurityUrlPaths.UserGroups], {
		keyToInvalidate: SecurityUrlPaths.Security,
	})
}

export const useSalesAssignments = (
	options?: ApiGetQueryOptions<SalesAssignment[], SalesAssignmentsQueryParams>
): ApiGetQuery<SalesAssignment[]> => {
	const [data, ...restOfResponse] = useGetRequest(
		[SecurityUrlPaths.Security, SecurityUrlPaths.SalesAssignments],
		options
	)

	return [data || [], ...restOfResponse]
}

export const useCreateSalesAssignment = (): ApiPostMutation<CreateSalesAssignmentPost> => {
	return usePostRequest([SecurityUrlPaths.Security, SecurityUrlPaths.SalesAssignments], {
		keyToInvalidate: SecurityUrlPaths.Security,
	})
}

export const useDeleteSalesAssignment = (): ApiDeleteMutation => {
	return useDeleteRequest([SecurityUrlPaths.Security, SecurityUrlPaths.SalesAssignments], {
		keyToInvalidate: SecurityUrlPaths.Security,
	})
}

export const useEnterprises = (): ApiGetQuery<Enterprise[]> => {
	return useGetRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Enterprises])
}

export const useCreateEnterprise = (): ApiPostMutation<CreateEnterprisePost, string> => {
	return usePostRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Enterprises], {
		keyToInvalidate: [SecurityUrlPaths.Security, LookupsUrlPaths.Lookups],
	})
}

export const useUpdateEnterprise = (): ApiPatchMutation<UpdateEnterprisePatch> => {
	return usePatchRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Enterprises], {
		keyToInvalidate: SecurityUrlPaths.Security,
	})
}

export const useOrganizations = (
	options?: ApiGetQueryOptions<Organization[], OrganizationQueryParams>
): ApiGetQuery<Organization[]> => {
	return useGetRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Organizations], options)
}

export const useCreateOrganization = (): ApiPostMutation<CreateOrganizationPost, string> => {
	return usePostRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Organizations], {
		keyToInvalidate: [SecurityUrlPaths.Security, LookupsUrlPaths.Lookups],
	})
}

export const useUpdateOrganization = (): ApiPatchMutation<UpdateOrganizationPatch> => {
	return usePatchRequest([SecurityUrlPaths.Security, SecurityUrlPaths.Organizations], {
		keyToInvalidate: SecurityUrlPaths.Security,
	})
}

export const useOrganizationCustomers = (
	organizationId: string | null | undefined
): ApiGetQuery<OrganizationCustomer[]> => {
	return useGetRequest(
		[
			SecurityUrlPaths.Security,
			SecurityUrlPaths.Organizations,
			organizationId ?? "",
			SecurityUrlPaths.Customers,
		],
		{
			queryConfig: {
				enabled: !!organizationId,
			},
		}
	)
}

export const useUpdateOrganizationCustomers = (
	organizationId: string
): ApiPostMutation<UpdateOrganizationCustomersPost> => {
	return usePostRequest(
		[
			SecurityUrlPaths.Security,
			SecurityUrlPaths.Organizations,
			organizationId,
			SecurityUrlPaths.Customers,
		],
		{
			keyToInvalidate: [SecurityUrlPaths.Security],
		}
	)
}
