import React, { FocusEventHandler, ReactNode, useMemo } from "react"

import { css } from "@emotion/react"
import { Radio } from "@material-ui/core"

import { accessBoolean, accessString, BooleanAccessor, StringAccessor } from "@ncs/ts-utils"

import { ControlLabel, Paragraph } from "../typography"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export interface RadioGroupEcommProps<Option>
	extends Omit<FieldContainerProps, "label" | "onClick"> {
	/** Just used in the HTML, doesn't render anywhere. */
	htmlName: string
	value: string | null
	onChange: (newValue: string, selectedOption: Option) => void
	options: Option[]
	/**
	 * Get the radio option's value.
	 * @default "value"
	 */
	valueAccessor?: StringAccessor<Option>
	/**
	 * Get the radio option's label.
	 * @default "label"
	 */
	labelAccessor?: StringAccessor<Option>
	/**
	 * Should the option be disabled?
	 * @default "disabled"
	 */
	disabledAccessor?: BooleanAccessor<Option>
	onBlur?: FocusEventHandler
	/** Paragraph that sits above the radio options to describe them. */
	description?: string | ReactNode
	/** Vertical or horizontal list? Defaults to vertical. */
	horizontal?: boolean
	/**
	 * Disable all options.
	 */
	disabled?: boolean
	extraComponent?: (selectedOption: Option) => any
}

export const RadioGroupEcommWithoutMemo = <Option,>({
	htmlName,
	value,
	onChange,
	options,
	valueAccessor = "value" as keyof Option,
	labelAccessor = "label" as keyof Option,
	disabledAccessor = "disabled" as keyof Option,
	onBlur,
	description,
	horizontal = false,
	disabled: globalDisabled,
	extraComponent,
	...rest
}: RadioGroupEcommProps<Option>): React.ReactElement => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value

		if (onChange) {
			// Find the option user selected in the options array and pass that out too.
			const selectedOption = options.find((option) => {
				const optionValue = accessString(option, valueAccessor)

				return newValue === optionValue
			})

			onChange(newValue, selectedOption as Option)
		}
	}

	const horizontalCss = useMemo(() => {
		return css`
			display: flex;
			column-gap: 1rem;
			margin-left: 0;
		`
	}, [])

	return (
		<FieldContainer {...rest}>
			{description && <Paragraph mb={0.3}>{description}</Paragraph>}

			<ul css={[listStyle, horizontal ? horizontalCss : undefined]}>
				{options.map((option) => {
					const optionValue = accessString(option, valueAccessor)
					const label = accessString(option, labelAccessor)
					const disabled =
						!!globalDisabled || accessBoolean(option, disabledAccessor, false, true)
					const id = `${htmlName}-radio-${optionValue}`

					return (
						<li key={optionValue} css={listItemStyle}>
							<Radio
								id={id}
								name={htmlName}
								color="primary"
								value={optionValue}
								checked={value === optionValue}
								onChange={handleChange}
								disabled={disabled}
								css={radioStyle}
								onBlur={onBlur}
							/>
							<ControlLabel
								htmlFor={id}
								checked={value === optionValue}
								disabled={disabled}
							>
								{label}
							</ControlLabel>
							{extraComponent ? extraComponent(option) : null}
						</li>
					)
				})}
			</ul>
		</FieldContainer>
	)
}

export const RadioGroupEcomm = React.memo(
	RadioGroupEcommWithoutMemo
) as typeof RadioGroupEcommWithoutMemo

const listStyle = css`
	position: relative;
	left: -0.5rem; // Make up for the large padding of the ripple area
	margin: 0;
	padding: 0;
`
const listItemStyle = css`
	display: flex;
	align-items: flex-start;
	margin-bottom: -0.35rem; // Large padding makes row padding look a bit too big.
	label {
		margin-top: 0.45rem;
	}
	&:last-of-type {
		margin-bottom: 0;
	}
`
const radioStyle = css`
	svg {
		width: 0.75em;
		height: 0.75em;
	}
`
