import { FC, useEffect, useState } from "react"

import {
	makeApiErrorMessage,
	useUpdateFreightCaps,
	FreightCap,
	FreightTypeDescription,
	FreightTypeId,
	useAuth,
} from "@ncs/ncs-api"

import {
	RadioBoolean,
	ExtendableModalProps,
	Modal,
	NumericInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

// import { FreightTypeSelector } from "./FreightTypeSelector"

export interface EditFreightCapModalProps extends ExtendableModalProps {
	freightCapProp: FreightCap | null
}

export function getFreightTypeDescription(freightTypeIdString: string): string {
	switch (freightTypeIdString) {
		case FreightTypeId.Ground:
			return FreightTypeDescription.Ground

		case FreightTypeId.PriorityOvernight:
			return FreightTypeDescription.PriorityOvernight

		default:
			return "N/A"
	}
}

export const EditFreightCapModal: FC<EditFreightCapModalProps> = ({ freightCapProp, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { isSaving, setSaving, endSaving } = useIsSaving()
	const [errorText, setErrorText] = useState<string | null>(null)

	const [freightCap, setFreightCap] = useState<number | null>(null)
	const [freightTypeId, setFreightTypeId] = useState<number | null>(null)
	const [freightType, setFreightType] = useState<string | null>(null)
	const [active, setActive] = useState<boolean | null>(null)

	const { user: authedUser } = useAuth()

	useEffect(() => {
		setFreightCap(freightCapProp?.threshold ? freightCapProp.threshold : null)
		setFreightTypeId(freightCapProp?.freightCapType ? freightCapProp.freightCapType : null)
		setFreightType(
			freightCapProp?.freightCapType ?
				getFreightTypeDescription(String(freightCapProp.freightCapType))
			:	null
		)

		if (freightCapProp && freightCapProp?.thresholdActive !== null) {
			setActive(freightCapProp.thresholdActive)
		}
	}, [freightCapProp])

	const updateFreightCaps = useUpdateFreightCaps()

	const handleSave = async () => {
		try {
			setSaving()
			const userId: number = authedUser?.id ? Number(authedUser.id) : 0

			if (!freightCap || !userId || !freightTypeId || active === null) {
				throw new Error("All fields are required")
			}

			await updateFreightCaps({
				threshold: freightCap,
				freightCapType: Number(freightTypeId),
				thresholdActive: active,
				userId: userId,
			})

			rest.onClose()
			makeSuccessToast("freightCap Edited")
		} catch (e) {
			endSaving()
			setErrorText(makeApiErrorMessage(e))
		}
	}

	useEffect(() => {
		setErrorText(null)
	}, [active, freightCap, freightTypeId, freightType])

	return (
		<Modal
			{...rest}
			title={`Edit ${freightType} Freight Cap`}
			errorText={errorText}
			rightButtons={{
				buttonText: "Create",
				onClick: handleSave,
				isLoading: isSaving(),
			}}
		>
			<NumericInput
				value={freightCap}
				onChange={(v) => setFreightCap(v ?? null)}
				label="Freight Cap $"
				fixedDecimalScale
				decimalScale={2}
			/>

			{/* <FreightTypeSelector value={freightTypeId} onChange={setFreightTypeId}/> */}

			<RadioBoolean
				htmlName="freight-cap-active"
				value={active}
				onChange={setActive}
				yesText="Freight cap active"
				noText="Freight cap disabled"
			/>
		</Modal>
	)
}
