import { FC, useEffect, useState } from "react"

import { useExportFreightCaps, useAuth } from "@ncs/ncs-api"

import { ExtendableModalProps, Modal, Paragraph } from "@ncs/web-legos"

export const ExportFreightCapModal: FC<ExtendableModalProps> = ({ ...rest }) => {
	const [errorText, setErrorText] = useState<string | null>(null)
	const exportFreightCaps = useExportFreightCaps()
	const { user } = useAuth()

	useEffect(() => {
		const userId: number | undefined = Number(user?.id)
		if (userId) {
			exportFreightCaps({ userId: userId })
				.then((data) => data)
				.catch((err) => setErrorText("there was an error creating the freight cap report"))
		} else {
			setErrorText("no user")
		}
	}, [user?.id, exportFreightCaps])

	return (
		<Modal {...rest} title={"Exporting Freight Cap History"} errorText={errorText}>
			<Paragraph mb={1} mr={1}>
				Sending freight cap report to {user?.email}
			</Paragraph>
		</Modal>
	)
}
