import { AxiosError, AxiosResponse } from "axios"
import {
	QueryKey,
	UseInfiniteQueryOptions,
	UseInfiniteQueryResult,
	UseMutateAsyncFunction,
	UseQueryOptions,
	UseQueryResult,
} from "react-query"
import { z } from "zod"

import { CamelToSnakeCaseNested, HeaderLinks, isLiteralObject } from "@ncs/ts-utils"

import { PreparePortalParamOptions } from "../util"

export type ApiGetQuery<ResponseData = unknown> = [
	data: ResponseData | undefined,
	isLoading: boolean,
	extras: {
		query: UseQueryResult<ResponseData, AxiosError>
		linkHeaders: HeaderLinks | null
		queryKey: (string | object)[]
	},
]

export type ApiGetQueryOptions<ResponseData = unknown, Params = {}> = {
	params?: Partial<Params>
	queryConfig?: UseQueryOptions<ResponseData, AxiosError>
	mapper?: (item: CamelToSnakeCaseNested<ResponseData>) => ResponseData
	preparePortalParamsOptions?: Partial<PreparePortalParamOptions>
}

export type ApiPostMutation<RequestData = void, ResponseData = unknown> = UseMutateAsyncFunction<
	AxiosResponse<CamelToSnakeCaseNested<ResponseData>>,
	unknown,
	RequestData,
	unknown
>

export interface ApiPostMutationOptions<RequestData = void, ResponseData = unknown> {
	keyToInvalidate?: string | string[] | (string | string[])[]
	pythonifyExcludeList?: string[]
	/** You usually don't need an ID for a POST, but there are endpoints in the portal that work this way.
	 * Because of limitations in React Query's `useMutation` function, we have to put that ID in the POST
	 * object. This flag will tell the hook to look for a param called `id` and add it to the end of the
	 * URL. It is then removed from the POST body.
	 */
	addRequestDataIdToUrl?: boolean
	/** Adds any additional URL segments to the endpoint. If you use `addRequestDataIdToUrl`, this will
	 * get added after that. */
	urlPathsAfterId?: string | string[]
	/** Callback to fire on success. */
	onSuccess?: (
		data: AxiosResponse<CamelToSnakeCaseNested<ResponseData>>,
		requestData: RequestData
	) => void
	/** Callback to fire on error. */
	onError?: (e: unknown) => void
}

export type ApiPatchMutation<
	RequestData extends {} | void = void,
	ResponseData = unknown,
> = UseMutateAsyncFunction<
	AxiosResponse<CamelToSnakeCaseNested<ResponseData>>,
	unknown,
	RequestData extends void ? void | { id?: string | number }
	:	{ updates: RequestData; id?: string | number },
	unknown
>

export interface ApiPatchMutationOptions<RequestData extends {} | void> {
	keyToInvalidate?: string | string[] | (string | string[])[]
	pythonifyExcludeList?: string[]
	/**
	 * These get added to the final URL after the ID is injected.
	 */
	urlPathsAfterId?: string | string[]
	/**
	 * Function to be run against the patch data. Throw an error if something's wrong.
	 */
	validatePayload?: (data: RequestData) => void
	/**
	 * Any additional params to add to the URL (not in the body).
	 */
	queryParams?: Record<string, string | number | boolean>
	/**
	 * Send the request as a PUT rather than a PATCH.
	 */
	putNotPatch?: boolean
}

export type ApiDeleteMutation<BodyData = void, ParamsObject = void> = UseMutateAsyncFunction<
	AxiosResponse<void>,
	unknown,
	MutationUrlConfig<BodyData, ParamsObject>,
	unknown
>

export interface ApiDeleteMutationOptions {
	keyToInvalidate?: string | string[] | (string | string[])[]
	urlSegmentsAfterId?: string | string[]
}

export interface ResponseDataWithPagination<ResponseData> {
	data: ResponseData
	nextPageNum: string | null
}

export interface ApiInfiniteGetQuery<ResponseData> {
	data: ResponseData[]
	isLoading: boolean
	isFetching: boolean
	hasNextPage: boolean
	hasPreviousPage: boolean
	fetchNextPage: () => void
	currentPage: number | undefined
	resultCountEstimate: number | null
	resultPageCount: number | undefined
	queryKey: QueryKey
	rawQuery: UseInfiniteQueryResult<ResponseDataWithPagination<ResponseData>, AxiosError>
	isActive?: boolean | null
}

export interface ApiPaginationGetQuery<ResponseData> {
	data: ResponseData[]
	isLoading: boolean
	isFetching: boolean
	hasNextPage: boolean
	hasPreviousPage: boolean
	resultCountEstimate: number | null
	resultPageCount: number | undefined
	currentPage: number | undefined
	queryKey: QueryKey
	rawQuery: UseInfiniteQueryResult<ResponseDataWithPagination<ResponseData>, AxiosError>
	fetchPage: (page: number) => void
}

/** Note that `ResponseData` should be typed as the object you'll be getting back an array of,
 *  not an array of that object. */
export interface ApiInfiniteGetQueryOptions<ResponseData, QueryParams> {
	// The query params, plus optionally pagination (if you're doing it manually).
	params?: Partial<QueryParams> & { page?: number; pageSize?: number }
	/** Do you want to control the pagination keys in the params, like any other param? */
	manualPagination?: boolean
	/** This one's used when not doing manual pagination and you're loading more into infinite rows.  */
	pageSize?: number | string
	queryConfig?: UseInfiniteQueryOptions<ResponseDataWithPagination<ResponseData>, AxiosError>
	mapper?: (item: CamelToSnakeCaseNested<ResponseData>) => ResponseData
	debuggingName?: string
}

export type MutationUrlConfig<BodyData = void, ParamsObject = void> =
	| string
	| {
			id?: string | number
			urlSegmentsAfterId?: string | string[]
			params?: ParamsObject
			prepareParamOptions?: PreparePortalParamOptions
			body?: BodyData
			pythonifyBodyExcludedKeys?: string | string[]
	  }

export type ApiCallableGetRequest<RequestData extends {}, ResponseData = void> = (
	params: RequestData
) => Promise<AxiosResponse<CamelToSnakeCaseNested<ResponseData>>>

export type ApiDelayedRequest<ResponseData> = [ResponseData | undefined, boolean]

export type DelayedRequestResponse = {
	delayedResponseId: number
}

export const isDelayedRequestResponse = (data: unknown): data is DelayedRequestResponse => {
	return !!data && isLiteralObject(data) && Object.keys(data).includes("delayedResponseId")
}

export type ApiDelayedRequestConfig = {
	/**
	 * Turn the hook off or on.
	 * @default true
	 */
	enabled: boolean
	/**
	 * How long before the data is considered stale?
	 * @default 300000 (5 minutes)
	 */
	staleTime: number
	/**
	 * How many times should we query the backend for the completed JSON data?
	 * @default 200
	 */
	maximumAttempts: number
	/**
	 * How many milliseconds should elapse between each check to the backend?
	 * @default 5000
	 */
	retryInterval: number
}

/* Common query params you can extend. */

export const SearchQueryParamsSchema = z.object({
	search: z.string().nullable(),
})
export type SearchQueryParams = z.infer<typeof SearchQueryParamsSchema>

export const OrderingQueryParamsSchema = z.object({
	ordering: z.string().nullable(),
})
export type OrderingQueryParams = z.infer<typeof OrderingQueryParamsSchema>
