import { FC } from "react"

import { Card, Tabs, useUrlState } from "@ncs/web-legos"

import {
	AccessorialsTab,
	CustomerExceptionsTab,
	FreightRatesTab,
	FuelSurchargesTab,
	MinimumsTab,
	FreightCapTab,
} from "./components"

export enum FreightPricingTab {
	Accessorials = "Accessorials",
	CustomerExceptions = "Customer Exceptions",
	FuelSurcharges = "Fuel Surcharges",
	Minimums = "Minimums",
	FreightRates = "Freight Rates",
	FreightCap = "Freight Cap",
}

export type FreightPricingUrlState = {
	tab: FreightPricingTab | null
}

export const FreightPricing: FC = () => {
	const [{ tab }, { updateUrlValue }] = useUrlState<FreightPricingUrlState>({ tab: null })

	return (
		<Card headingIcon="truck-fast" heading="Freight Pricing">
			<Tabs
				currentTab={tab || FreightPricingTab.FreightRates}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: FreightPricingTab.FreightRates,
						component: <FreightRatesTab />,
					},
					{
						navLabel: FreightPricingTab.Minimums,
						component: <MinimumsTab />,
					},
					{
						navLabel: FreightPricingTab.CustomerExceptions,
						component: <CustomerExceptionsTab />,
					},
					{
						navLabel: FreightPricingTab.Accessorials,
						component: <AccessorialsTab />,
					},
					{
						navLabel: FreightPricingTab.FuelSurcharges,
						component: <FuelSurchargesTab />,
					},
					{
						navLabel: FreightPricingTab.FreightCap,
						component: <FreightCapTab />,
					},
				]}
			/>
		</Card>
	)
}
