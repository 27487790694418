import { createTheme } from "@material-ui/core/styles"
import { lighten } from "polished"

export const NCS_FONT = "'Atlas Grotesk', 'Helvetica', 'Arial', sans-serif"
export const NCS_PRIMARY = "#0A3D53"

export const theme = createTheme({
	overrides: {
		MuiBackdrop: {
			root: {
				backgroundColor: "rgba(0, 0, 0, 0.25)",
			},
		},
		MuiTooltip: {
			arrow: {
				color: "white",
			},
			tooltip: {
				backgroundColor: "white",
				boxShadow: "0 2px 30px rgba(0, 0, 0, 0.2)",
				padding: "0.6rem 1rem 0.85rem 1rem",
				fontSize: "1rem",
				color: "#393939",
				"& .icon-container": {
					position: "relative",
					top: "2px",
					paddingRight: "0.75rem",
					opacity: 0.75,
					lineHeight: 0,
				},
				"& .text": {
					paddingTop: "0.1rem",
					fontWeight: "normal",
				},
			},
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 2500, // make this bigger for touchscreen TVs
		},
	},
	defaultCardElevation: "5px",
	tableBoxShadow: "0 1.5px 3px -1px rgba(0, 0, 0, 0.15)",
	palette: {
		colors: {},
		background: {
			default: "#ffffff",
		},
		primary: {
			main: NCS_PRIMARY,
			light: lighten(0.15, NCS_PRIMARY),
		},
		error: {
			main: "#E02104",
			dark: "#830000",
		},
		success: {
			main: "#5ed362",
			dark: "#3BA840",
		},
		warning: {
			main: "rgb(255, 200, 0)",
			light: "#ffd400",
			contrastText: "#555",
		},
		text: {
			primary: "#393939",
			secondary: "#999",
		},
		cta: {
			light: lighten(0.07, NCS_PRIMARY),
			main: NCS_PRIMARY,
			dark: "#000000",
		},
		chartColors: [
			"#0A3D53",
			"#3299C1",
			"#327E77",
			"#005BA6",
			"#78D237",
			"#561D7C",
			"#FFAA46",
			"#FFC61B",
			"#DB007A",
			"#8EB3C1",
			"#C12122",
			"#00C9FF",
		],
	},
	layout: {
		contentOuterMaxWidth: "110rem",
	},
	spacing: 16,
	typography: {
		fontSize: 16,
		fontFamily: NCS_FONT,
		body1: {
			fontSize: "1rem",
		},
		h1: {
			fontSize: "1.75rem",
			fontWeight: "normal",
		},
		h2: {
			fontSize: "1.563rem",
			fontWeight: "normal",
		},
		h3: {
			fontSize: "1.313rem",
			fontWeight: "normal",
		},
		h4: {
			fontSize: "1.125rem",
			fontWeight: "normal",
		},
		h5: {
			fontSize: "1rem",
			fontWeight: "bold",
		},
		h6: {
			fontSize: "0.75rem",
			fontWeight: "normal",
			textTransform: "uppercase",
			letterSpacing: "0.25px",
		},
	},
	transitions: {
		duration: {
			enteringScreen: 500,
		},
	},
})
