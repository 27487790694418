import { FC, useMemo } from "react"

import { useHistory } from "react-router-dom"
import { Cell, Column } from "react-table"

import { defaultPosPartQueryParams, PosPart, PosPartQueryParams, usePosParts } from "@ncs/ncs-api"
import { formatCurrency, formatDateTime, maxChars, Typify } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Card,
	EmptyValueDash,
	Icon,
	Paragraph,
	PartCategoryQueryFilter,
	PartImage,
	PosPartActiveQueryFilter,
	PosPartFeaturedQueryFilter,
	PosPartClearanceQueryFilter,
	PosPartRelationshipQueryFilter,
	SearchQueryFilter,
	Table,
	usePaginationUrlState,
	useScrollToTopOnMount,
	useUrlState,
} from "@ncs/web-legos"

import { PageTitle } from "~/layouts/PageTitle"

const columns: Column<PosPart>[] = [
	{
		Header: "",
		id: "image",
		Cell: (cell: Cell<PosPart>) => {
			return (
				<PartImage
					src={cell.row.original.imageUrl}
					alt={cell.row.original.title}
					width={2}
				/>
			)
		},
	},
	{
		Header: "Product #",
		id: "part-number",
		Cell: ({ row: { original } }: Cell<PosPart>) =>
			original.onlinePartNumber ?
				<Box>
					{original.onlinePartNumber}
					{original.isChild && (
						<Paragraph color="secondary" small>
							Child
						</Paragraph>
					)}
				</Box>
			:	<Box display="flex" alignItems="center" columnGap={0.5}>
					<Icon icon="sitemap" color="gray" />
					<Paragraph color="secondary" small>
						Parent
					</Paragraph>
				</Box>,
	},
	{
		Header: "Name",
		accessor: "title",
	},
	{
		Header: "Price",
		accessor: "price",
		Cell: ({ row: { original } }: Cell<PosPart>) => {
			return original.price != null ? formatCurrency(original.price) : <EmptyValueDash />
		},
	},
	{
		Header: "Description",
		accessor: ({ description }) => maxChars(description, 200),
	},
	{
		Header: "Default Catalog?",
		accessor: "isActive",
		headingTooltip: "Is this product in the default customer catalog?",
		Cell: ({ row: { original } }: Cell<PosPart>) =>
			original.isActive ? <Icon icon="check" color="gray" /> : <EmptyValueDash />,
	},
	{
		// Featured products are now called Trending
		Header: "Trending",
		accessor: "featuredItem",
		headingTooltip: "Is this a trending product?",
		Cell: ({ row: { original } }: Cell<PosPart>) =>
			original.featuredItem ?
				<Icon icon="star" family="solid" color="gray" />
			:	<EmptyValueDash />,
	},
	{
		Header: "Clearance",
		accessor: "clearanceItem",
		headingTooltip: "Is this a clearance product?",
		Cell: ({ row: { original } }: Cell<PosPart>) =>
			original.clearanceItem ?
				<Icon icon="star" family="solid" color="gray" />
			:	<EmptyValueDash />,
	},
	{
		Header: "Last modified",
		accessor: "lastModified",
		Cell: ({ row: { original } }: Cell<PosPart>) =>
			original.lastModified ? formatDateTime(original.lastModified) : <EmptyValueDash />,
	},
	{
		Header: "Modified By",
		accessor: "modifiedBy",
		hiddenByDefault: true,
		Cell: ({ row: { original } }: Cell<PosPart>) =>
			original.modifiedBy?.name ?? <EmptyValueDash />,
	},
]

export type PosPartsUrlState = Typify<PosPartQueryParams>

const PosParts: FC = () => {
	useScrollToTopOnMount()
	const history = useHistory()

	const [filterParams, { setUrlState }] = useUrlState<PosPartsUrlState>({
		...defaultPosPartQueryParams,
		ordering: "-lastModified",
	})
	const [pagination, setPagination] = usePaginationUrlState()
	const params = {
		...filterParams,
		page: pagination.page,
		pageSize: pagination.pageSize,
	}

	const partsQuery = usePosParts({
		manualPagination: true,
		params,
		queryConfig: {
			refetchOnWindowFocus: true,
		},
	})

	const filtersStartOpen = useMemo(() => {
		const keys: (keyof typeof filterParams)[] = ["featured", "parent", "active", "clearance"]
		return keys.some((key) => filterParams[key] != null)
	}, [filterParams])

	return (
		<>
			<PageTitle title="Point Of Sale Products" />

			<Card
				heading="Point of Sale Products"
				headingIcon="tags"
				renderRight={() => (
					<Button
						icon="plus-circle"
						onClick={() =>
							history.push({
								pathname: "/pos-products/product",
								state: { params },
							})
						}
					>
						Create new point of sale product
					</Button>
				)}
			>
				<Table
					query={partsQuery}
					columns={columns}
					queryParamState={filterParams}
					setQueryParamState={setUrlState}
					pinnedQueryFilters={[SearchQueryFilter, PartCategoryQueryFilter]}
					toggledQueryFilters={[
						PosPartActiveQueryFilter,
						PosPartRelationshipQueryFilter,
						PosPartFeaturedQueryFilter,
						PosPartClearanceQueryFilter,
					]}
					pagination={pagination}
					setPagination={setPagination}
					rowVerticalAlign="middle"
					onRowClick={(row) =>
						history.push({
							pathname: `/pos-products/product/${row.original.id}`,
							state: { params },
						})
					}
					showToggledFiltersByDefault={filtersStartOpen}
				/>
			</Card>
		</>
	)
}

export default PosParts
