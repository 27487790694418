import { Dispatch, ReactElement, SetStateAction } from "react"

import { PosPartClearanceSelector } from "../selectors"

export interface PosPartClearanceQueryParams {
	clearance: boolean | null
}

export interface PosPartClearanceQueryFilterProps<
	QueryParamState extends PosPartClearanceQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PosPartClearanceQueryFilter = <QueryParamState extends PosPartClearanceQueryParams>({
	queryParamState,
	setQueryParamState,
}: PosPartClearanceQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			clearance: newValue === "clearance" || null,
		}))
	}

	return (
		<PosPartClearanceSelector
			value={queryParamState.clearance ? "clearance" : null}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
		/>
	)
}
