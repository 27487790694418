import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { PartMinimal } from "../common"
import { CustomerMinimal, CustomerMinimaler, CustomerWithAddress } from "../customers"
import { PartOrderLineMin } from "../parts-order"
import { UserMinimal } from "../security"

export enum InventoryUrlPaths {
	AllLocationBins = "all_location_bins",
	Allocation = "allocation",
	BillOfMaterial = "bill_of_material",
	BillOfMaterials = "bill_of_materials",
	BinParts = "bin_parts",
	Bins = "bins",
	SimpleBins = "simple_bins",
	ChemicalForecast = "chemical_forecast",
	ChemicalForecasts = "chemical_forecasts",
	CustomerRestrictedParts = "customer_restricted_parts",
	CycleCount = "cycle_count",
	CycleSheetRequest = "cycle_sheet_request",
	CycleSheetSubmission = "cycle_sheet_submission",
	Demand = "demand",
	Detail = "detail",
	Documents = "documents",
	Email = "email",
	FullLocationExport = "full_location_export",
	GenericLabels = "generic_labels",
	GetBinInformation = "get_bin_information",
	Inventory = "inventory",
	InventoryRequestCreate = "inventory_request_create",
	InventoryRequestDetail = "inventory_request_detail",
	InventoryRequests = "inventory_requests",
	InventoryTransactionInitial = "inventory_transaction_initial",
	Levels = "levels",
	Lines = "lines",
	LocalPart = "local_part",
	Location = "location",
	LocationBinLabels = "location_bin_labels",
	LocationBins = "location_bins",
	LocationInventoryCheatSheet = "location_inventory_cheat_sheet",
	Locations = "locations",
	ManufacturedParts = "manufactured_parts",
	OpenOrders = "open_orders",
	OrderBins = "order_bins",
	OverStocked = "over_stocked",
	Part = "part",
	PartAvailability = "part_availability",
	PartGroup = "part_group",
	PartGroupDiscount = "part_group_discount",
	PartGroupMember = "part_group_member",
	PartReplacement = "part_replacement",
	Parts = "parts",
	PickList = "pick_list",
	PickListAssignments = "pick_list_assignments",
	PickListComplete = "pick_list_complete",
	PickListInProgress = "pick_list_in_progress",
	PickListReassign = "pick_list_reassign",
	RdcPartBulkAdd = "rdc_part_bulk_add",
	RdcPartSummaryUrlPath = "rdc_part_summary",
	RdcPartUrlPath = "rdc_part",
	Replenishment = "replenishment",
	ReplenishmentIgnore = "replenishment_ignore",
	ReplenishmentOverstock = "replenishment_overstock",
	Stock = "stock",
	Submission = "submission",
	Submit = "submit",
	Summary = "summary",
	Transaction = "transaction",
	Transactions = "transactions",
	UpdatePartImage = "update_part_image",
	V2 = "v2",
	Vendor = "vendor",
	VendorContactInfo = "vendor_contact_info",
	WarehouseStock = "warehouse_stock",
}

export interface InventoryLocation {
	id: string
	companyId: number
	/** Can be an empty string. */
	description: string
	isTech: boolean
	isWarehouse: boolean
	locationCode: string
	regionId: number | null
	territoryId: number | null
	userId: number | null
	customerId: string | null | undefined
}

export enum PartReplacedParameter {
	N = "No",
	Y = "Yes",
	S = "See engineering information",
}

export interface InventoryPart {
	id: string
	abcCode: string | null
	description: string
	econOrderingAmount: number | null
	gallons: number | null
	hazmat: {
		description: string
		packingGroup: string
		shippingName: string
		hazmatType: string
		idNumber: string
		hazardClass: string
		additionalInformation: string | null
		partId: string
	} | null
	height: number
	imageUrl: string | null // Uses OnlinePart's image as a fallback.
	partImageUrl: string | null // Just this part's image_url field. No fallback.
	isActive: boolean
	netsuiteId: string | null
	isOutOfStock: boolean
	isService: boolean
	length: number
	listPrice: number
	netPrice: number
	otherPartNumbers: string[]
	partFamily: string | null
	partNumber: string
	partReplaced: PartReplacedParameter
	quantityPerPallet: number
	replacedBy: string | null
	revision: string
	standardCost: number
	status: string | null
	unitOfMeasure: string | null
	warrantyFlag: boolean
	weight: number
	width: number
	unspscNumber: number | null
	upcCode: string | null
	restricted: boolean
	freightGroupId: number | null
	freightGroupDescription: string | null
	freezeProtection: boolean
	packageSizeInGallons: number
}

export interface InventoryPartQueryParams extends SearchQueryParams, OrderingQueryParams {
	partNumber: string | null
	description: string | null
	unitOfMeasure: string | null
	allParts: boolean | null
	service: boolean | null
	restricted: boolean | null
	restrictionsCustomer: string | null
	skipRestrictedCheck: boolean | null
}

export type UpdateInventoryPartPatch = Partial<{
	height: number | string | null
	width: number | string | null
	length: number | string | null
	weight: number | string | null
	gallons: number | null
	quantityPerPallet: number
	isActive: boolean | null
	partFamily: string | null
	status: string | null
	upcCode: string | null
	unspscNumber: number | string | null
	newImageUrl: string | null
	hazmat: {
		additionalInformation: string | null
		description: string
		hazardClass: string | null
		hazmatType: string
		idNumber: string
		packingGroup: string
		shippingName: string
	} | null
	restricted: boolean
	freightGroupId: string | null
	freezeProtection: boolean
}>

export interface UpdateInventoryPartImagePost {
	partId: string
	file: File | null
}

export interface LocationMinimal {
	id: string
	locationCode: string
	description: string
	isTech: boolean
	isWarehouse: boolean
}

export interface OverstockedPartOld {
	amountOverStocked: number
	dollarsOverStocked: number
	maxOnHand: number
	partDescription: string
	partId: number
	partNumber: string
	totalOnHand: number
}

export interface StalePartOld {
	dollarsOverStocked: number
	partDescription: string
	partId: number
	partNumber: string
	totalQuantity: number
}

export interface StaleAndOverstockedParts {
	overstockedParts: OverstockedPartOld[]
	staleParts: StalePartOld[]
}

export enum InventoryPartType {
	All = "all",
	Parts = "part",
	Chemicals = "chem",
}

export interface StaleAndOverstockedPartsPost {
	// Note that normally the optional ones would be `value | null`, but because
	// of the way this endpoint is set up the backend will return error on `null`,
	// so it really is specifically `value | undefined`.
	locationId: string
	partType: InventoryPartType | undefined
	chemicalTurns: number | undefined
	partTurns: number | undefined
	staleInventoryTiming: number | undefined
	vendor?: number
}

export interface ReplenishmentIgnoredPart {
	id: string
	part: PartMinimal | null
	location: LocationMinimal | null
}

export interface OpenFulfillmentPart {
	id: string
	part: PartMinimal | null
	location: LocationMinimal | null
}

export interface AddOpenFulfillmentPartPost {
	partId: number
	locationId: string
}

export interface Vendor {
	id: number
	name: string
	vendorCode: string
}

export interface LocationVendorSummariesParams {
	location: string | null
	partType: InventoryPartType | null
}

export interface LocationVendorSummary {
	vendor: Vendor
	total: number
	stock: number
	nonStock: number
}

export enum VendorPartRequestType {
	Order = "Order",
	Stock = "Stock",
	Priority = "Priority",
}

export interface VendorPartRequest {
	ids: (string | number)[] | null // Demand IDs.
	part: PartMinimal
	quantity: number
	reroutable: boolean
	stockPart: boolean
	type: VendorPartRequestType
}

export interface LocationVendorRequests {
	parts: VendorPartRequest[]
	vendor: {
		id: number
		address: string | null
		city: string | null
		contactName: string | null
		name: string
		phone: string | null
		state: string | null
		vendorCode: string
		zipCode: string | null
	}
}

export interface LocationVendorRequestsParams {
	location: string | null
	vendor: string | null
	partHistoryCycle: number | null
	partType: InventoryPartType | null
	demandId: string | null
}

export interface VendorAlternative {
	id: number // Location ID
	location: string
	locationCode: string
	quantityAllocated: number | null
	quantityOnHand: number | null
}

export interface VendorPartRequestDetail {
	id: string
	description: string
	partNumber: string
	unitOfMeasure: string | null
	imageUrl: string | null
	max: number
	manualReorderPoint: number | null // Prefer manual...
	calcReorderPoint: number | null // ...but fallback to calc.
	standardCost: number
	minOrderQuantity: number | null
	leadTime: string | null
	econOrderAmount: null
	palletQuantity: number
}

export interface VendorOrderRequestDetail {
	id: string // Part Order ID
	customer: string
	customerNumber: string
	customerAddress: string
	customerCity: string
	customerState: string
	customerZip: string
	order: number // Part Order Order ID
	orderDate: string
	orderedBy: string
	quantity: number
}

export enum ReplenishmentSubmissionType {
	Order = "order",
	DropShip = "direct",
	Reroute = "reroute",
}

export interface AddRequestsToShipmentPost {
	demands: (string | number)[] | null
	parts:
		| null
		| {
				partId: string
				quantity: number
		  }[]
	submissionType: ReplenishmentSubmissionType
	locationId: string // Usually the same location, but will be different on a reroute.
	vendorId: string
}

export interface AddRequestsToShipmentPostResponse {
	customer: string
	customerNumber: string
	purchaseOrderId: string | null
	purchaseOrderStatus: string | null
	partOrderId: string | null
	partOrderStatus: string | null
	partOrderOrderId: string | null
}

export interface VendorManagement {
	id: string
	contactInfo: VendorContactInfo | null
	vendorCode: string | null
	name: string | null
	address1: string | null
	address2: string | null
	address3: string | null
	city: string | null
	state: string | null
	zipCode: string | null
	isActive: boolean
	contactName: string | null
	phone: string | null
	kbmActiveCode: string | null
}

export interface VendorManagementsQueryParams extends SearchQueryParams, OrderingQueryParams {}

export interface VendorManagementPatch {
	isActive: boolean
}

export interface VendorContactInfo {
	contactName: string | null
	email: string | null
	phone: string | null
	website: string | null
}

export interface VendorContactInfoPatch extends VendorContactInfo {
	vendorId: string
}

export interface RdcPart {
	id: string
	part: PartMinimal
	location: LocationMinimal
	localPartVendorId: number | null
	minOrderQuantity: number
	locationMax: number
	locationMin: number
	econOrderAmount: number
	leadTime: number
}

export interface RdcPartsQueryParams extends SearchQueryParams, OrderingQueryParams {
	part: string | null
	location: string | null
}

export interface RdcPartPost {
	partId: string
	locationId: string
	vendorId: string
	econAmount?: number // Write for `econ_order_amount`
	leadTimeDays?: number // Write for `lead_time`
	maxLevel?: number // Write for `location_max`
	minLevel?: number // Write to `location_min`
	minAmount?: number // Write for `min_order_qty`
}

export type RdcPartBulkPost = FormData

export interface RdcPartSummary {
	parts: number
	chemicals: number
	detail: number
}

export interface ChemicalForecast {
	id: string
	createdBy: UserMinimal
	customerName: string
	address1: string | null
	address2: string
	city: string
	state: string
	postal: string
	trialDate: string // naive date!
	orderFrequency: string
	fulfillmentExpectations: string
	comments: string | null
	createdDate: string
	billToName: string | null
	billToAddress1: string | null
	billToAddress2: string | null
	billToCity: string | null
	billToState: string | null
	billToPostal: string | null
	billToSelf: boolean
	billToId: CustomerWithAddress | null
	items: {
		id: string
		quantity: number
		item: {
			id: string
			part: PartMinimal
			category: string
			alternateDescription: string | null
			packageType: string | null
		}
	}[]
}

export interface ChemicalForecastQueryParams extends SearchQueryParams, OrderingQueryParams {}

export interface ChemicalForecastItem {
	id: string
	name: string
	packageType: string | null
	partNumber: string
	category: string
}

export interface ChemicalForecastItemsQueryParams extends SearchQueryParams {}

export interface ChemicalForecastPost {
	customer: string
	city: string
	postal: string
	state: string
	address1: string | null
	address2: string
	orderFrequency: string
	fulfillmentExpectations: string
	comments: string | null
	trialDate: string
	parts: {
		id: string
		quantity: number
	}[]
	billToIdId: string | null
	billToSelf: boolean
	billToName: string | null
	billToAddress1: string | null
	billToAddress2: string | null
	billToCity: string | null
	billToState: string | null
	billToPostal: string | null
}

export type ChemicalForecastPatch = ChemicalForecastPost & {
	id: string
}

export interface LocationBinQueryParams {
	fields: string | null
}

export interface PartDefaultBin {
	partId: number

	// This comes back if you passed a part order.
	binId?: number

	// These come back if you passed a purchase order.
	orderBinId?: number // (The bin for when it's an order)
	stockBinId?: number // (The bin for when it's stock)
}

export interface DefaultOrderBinsQueryParams {
	location: string | null
	partOrder: string | null
	purchaseOrder: string | null
}

export enum StockStatus {
	All = "all",
	Partial = "partial",
	None = "none",
	Unknown = "unknown",
}

/**
 * The stock status of a given part order at a warehouse.
 */
export interface PartOrderWarehouseStock {
	locationId: string
	locationCode: string
	locationDescription: string
	stockStatus: StockStatus
}

export interface OverstockedPart {
	cost: number
	marked?: boolean
	maxLevel: number
	partDescription: string
	partId: number
	partNumber: string
	quantity: number
	quantityOverStocked?: number
	stockPart: boolean
}

export interface OverstockedPartQueryParams {
	partType: string | null
}

export interface DemandVendorUpdatePost {
	demandId: number
	vendorId: number
}

export interface PartGroup {
	id: string
	description: string
	isReusableGroup: boolean
}

export interface PartGroupPost {
	description: string
	isReusableGroup: boolean
}

export interface PartGroupPatch {
	description: string
	isReusableGroup: boolean
}

/** A part in the Part Group.  */
export interface PartGroupMember {
	id: string
	part: {
		id: string
		partNumber: string
		description: string
	}
	group: PartGroup
}

export interface PartGroupMemberQueryParams extends SearchQueryParams {
	group: string | null
}

export interface PartGroupMemberPost {
	groupId: string
	partId: string
}

/** A customer that has a discount for a Part Group. */
export interface PartGroupDiscount {
	id: number
	groupDiscountPercent: string // For ex, "0.07"
	isActive: boolean
	customer: CustomerMinimal
	customerId: number // A big number. Same ID is inside customer object as a string.
	group: PartGroup
	groupId: number
	customerNumber: string
	groupDescription: string
}

export interface PartGroupDiscountQueryParams extends SearchQueryParams {
	group: string | null
}

export interface PartGroupDiscountPost {
	customerId: string
	groupDiscountPercent: number // For 7%, pass 0.07 not 7.
	groupId: string
	isActive: boolean
}

export type PartGroupDiscountPatch = Partial<{
	customerId: string
	groupDiscountPercent: number // For ex, "0.07"
	groupId: string
	isActive: boolean
}>

// This is actually represented in the database, not as an enum in the backend.
export enum PickListStatus {
	Open = "Open",
	ReadyToPick = "Ready To Pick",
	InProgress = "In Progress",
	Picked = "Picked",
	Removed = "Removed",
}

export interface PickListDeliveryOrder {
	id: string
	billToCustomer: CustomerMinimal
	createdDate: string
	customer: CustomerWithAddress
}

// Called `PickListLineItemsSerializer` ("items" plural) in the backend.
export interface PickListLineItem {
	id: string
	bin: {
		id: string
		code: string
		isDefault: boolean
		locationId: number
	}
	part: {
		id: string
		partNumber: string
		description: string
	}
	pickListId: number
	pickedTime: string | null
	quantity: number
}

export interface PickListQueryParams extends SearchQueryParams, OrderingQueryParams {
	startDate: string | null
	endDate: string | null
	pickListStatus: string | null
	location: string | null
	territory: string | null
	user: string | null
	partOrder: string | null
}

export interface CompletePickListPost {
	pickListId: string
	binId: string
	lines: {
		id: string
		quantity: number
	}[]
}

export interface SetPickListInProgressPost {
	inProgress: boolean
	pickListId: string
}

export interface InventoryTransaction {
	id: string
	comment: string | null
	createdBy: UserMinimal
	deliveryOrderId: number | null
	direction: "transfer" | "out" | "in" | ""
	dispatchId: string | null
	fromBin: BinLocationWithLocation | null
	partDescription: string
	partNumber: string
	partOrderNumber: string | null // The part order's KBM order number or its order ID.
	pickListId: number | null
	purchaseOrderId: number | null
	quantity: number // Can be negative
	toBin: BinLocationWithLocation | null
	transactionDate: string
	type: string
	workOrderNumber: string | null
}

export interface InventoryTransactionQueryParams extends SearchQueryParams, OrderingQueryParams {
	part: string | null
	bin: string | null
	location: string | null
}

export interface BinLocationWithLocation extends BinLocationMinimal {
	location: LocationMinimal
}

export interface BinLocation extends BinLocationMinimal {
	parts: LocationInventory[]
}

export interface BinLocationQueryParams {
	isActive: boolean | null
}

export interface BinLocationMinimal extends BinLocationMinimaler {
	locationId: number
}

export interface BinLocationMinimaler {
	id: string
	code: string
	isDefault: boolean
}

export interface LocationInventory {
	id: string
	partNumber: string
	quantity: string
	qtyAllocated: number
}

export interface BinManagementBin {
	id: string
	code: string
	isActive: boolean
	hasParts: boolean
	locationId: number
	binTypeId: number
	binType: string
}

export enum BinTypeId {
	Default = "1",
	Shipping = "2",
	Warranty = "3",
	Damaged = "4",
}

export interface BinManagementBinQueryParams extends OrderingQueryParams, SearchQueryParams {
	location: string | null
	isActive: boolean | null
	binType: string | null
}

export interface BinManagementBinPost {
	code: string
	isActive: boolean
	locationId: string
	binTypeId: string
}

export interface BinManagementBinPatch {
	code: string
	isActive: boolean
	locationId: string
	binTypeId: string
}

export interface LocationLevel {
	id: string
	binCode: string
	inventoryId: number
	locationQtyAllocated: number | null // Total number allocated at this location.
	manualReorderPoint: string
	maxReorderPoint: string
	part: PartWithCost
	quantity: number // Just the quantity in this specific bin.
	totalCost: number // Just the cost in this specific bin.
	totalQuantity: number // The total number of this part at this location.
}

export interface LocationLevelQueryParams extends SearchQueryParams {
	bin: string | null
	part: string | null
}

export interface PartWithCost {
	id: string
	partNumber: string
	description: string
	standardCost: string
}

export interface LocalPart {
	id: string
	cost: string
	location: LocationMinimal | null
	// locationId: number | null // Don't use, huge integer.
	vendorPart: {
		part: InventoryPart
		partId: string
		vendor: {
			name: string
		}
		vendorDescription: string | null // Vendor's description of the part, not a description of the vendor.
		vendorId: number
		vendorPartNumber: string | null
	}
}

export interface LocalPartQueryParams extends SearchQueryParams, OrderingQueryParams {
	territory: string | null
	location: string | null
	vendor: string | null
	part: string | null
}

export interface CreateInventorySheetPost {
	locationId: string
}

export interface LocationPartSummary {
	bins: {
		binCode: string
		binCost: number | null
		binId: number
		binPicking: number
		binQuantity: number
		binShipping: number
	}[]
	econOrderAmount: number | null
	isLocallySourced: boolean
	isStock: boolean
	leadTime: number | null
	localVendorPartCost: number | null
	localVendorPartName: string | null
	localVendorPartNumber: string | null
	maxLocationLevel: number | null
	minLocationLevel: number | null
	minOrderQuantity: number | null
	partId: string
	partImgUrl: string | null
	partName: string
	partNumber: string
	softAllocations?: number
	shipping: number
	picking: number
	vendor: {
		id: string
		name: string
	} | null
	quantityAvailable: number | null // Total at location
	quantityOnHand: number | null // Total at location
	totalCost: number | null // Total at location
}

export interface LocationPartSummaries {
	resultCount: number
	page: number
	pageSize: number
	summaries: LocationPartSummary[]
}

export interface LocationPartSummaryQueryParams extends SearchQueryParams {
	location: string
	part: string | null
	type: string | null
	bin: string | null
}

export interface LocationPartSummaryPost {
	partId: string
	maxLevel: number | null
	reorderPoint: number | null
	econOrderAmount: number | null
	minOrderQuantity: number | null
	leadTimeDays: number | null
	vendorId: string
	vendorPartNumber: string | null
	vendorPartDescription: string | null
	vendorPartCost: number | null
	stockPart: boolean
	localPart: boolean
	locationId: string
}

export type LocationPartSummaryPatch = LocationPartSummaryPost

export interface InventoryAllocation {
	id: string
	inventoryTransactionId: number | null
	locationInventory: LocationInventoryMin
	partOrderLine: PartOrderLineMin | null
	pickListLine: PickListLineItem | null
	quantity: number
}

export interface InventoryAllocationQueryParams extends OrderingQueryParams, SearchQueryParams {
	location: string | null
	part: string | null
}

export interface LocationInventoryMin {
	id: string
	bin: BinLocationMinimal
	inventory: InventoryMin
}

export interface InventoryMin {
	id: string
	location: LocationMinimal
}

export interface PickListLineItem {
	id: string
	part: {
		id: string
		partNumber: string
		description: string
	}
	bin: BinLocationMinimal
}

export type PartOrderAtLocation = {
	id: string | "None"
	orderId: number | null
	quantity: number
}

export type PurchaseOrderAtLocation = {
	id: string
	quantity: number
}

export interface LocationPartOrders {
	partId: string
	partNumber: string
	partName: string
	incomingPurchaseOrders: PurchaseOrderAtLocation[]
	incomingPartOrders: PartOrderAtLocation[]
	demands: PartOrderAtLocation[]
	picking: PartOrderAtLocation[]
	shipping: PartOrderAtLocation[]
}

export interface CycleCountPart {
	id: string
	partNumber: string
	partDescription: string
	dueDate: string
	completed: "No" | "Yes"
}

export interface RequestCycleCountSheetPost {
	locationId: string
	allUncompletedParts: boolean
	ids: string[] | null
}

export interface CycleSheetSubmissionPost {
	locationId: string
	countData: File
}

export interface PartLevel {
	id: string
	bin: BinLocationWithLocation
	locationQuantity: number | null
	quantity: string
	totalCost?: number // Cost is only there if API call is made by an employee user.
}

export interface PartLevelQueryParams extends OrderingQueryParams, SearchQueryParams {
	location: string | null
	territory: string | null
}

export interface CustomerRestrictedPart {
	id: string
	customer: CustomerMinimaler
	part: PartMinimal
}

export interface CustomerRestrictedPartQueryParams {
	customerId: string | null
	partId: string | null
}

export type AddCustomerRestrictedPartPost =
	| {
			shipToId: string
			partId: string
			billToId?: undefined
	  }
	| {
			billToId: string
			partId: string
			shipToId?: undefined
	  }

export interface CustomerRestrictedPartDelete {
	shipToId?: string
	billToId?: string
	partId?: string
}

export interface InventoryTransactionInitialData {
	locations: {
		text: string // The location name and code together
		value: number // The location ID
	}[]
	transactionTypes: {
		text: string
		requiredLocations: {
			dest: boolean
			source: boolean
		}
		value: number
	}[]

	// (Far as I can tell this part doesn't have a use)
	parts: {
		text: string // Part number and name
		value: number // Part ID
	}[]
}

export type InventoryTransactionType = InventoryTransactionInitialData["transactionTypes"][number]
export type InventoryTransactionLocation = InventoryTransactionInitialData["locations"][number]

export interface InventoryTransactionBins {
	// The parts and their bins.
	binDetails: {
		bins: {
			id: number
			quantity: number
			text: string // Bin code
			value: string // Also bin code
		}[]
		partDescription: string
		partId: number
		partNumber: string
		altPartNumber: string[] | null
		totalQty: number
	}[]
	// All the bins.
	binInfo: {
		id: number
		text: string // Bin code
		value: string // Also bin code
	}[]
}

export interface InventoryTransactionBinsPost {
	location: string
}

export interface CreateInventoryTransactionPost {
	parts: {
		destBinId?: string
		partId: string
		quantity: number
		sourceBinId?: string
	}[]
	destLocationId: string | null
	sourceLocationId: string | null
	transactionTypeId: number
}

export type LabelSize = "3x2" | "4x3" | "6x4" | "3.5x1.5" | "2x1"

export interface PrintBinLabelsRequest {
	location: string
	allBins: boolean
	bins: string[]
	size: LabelSize
}

export interface GenericLabelPost {
	labelSize: LabelSize
	labels: {
		label: string
		quantity: number
	}[]
}

export interface ReassignPickListPost {
	pickListId: string
	userId: string
}

export interface UpdatePickListPatch {
	pickListId: string
	shipDate: string | null
	comment: string | null
	cancel: boolean
}

export type RepairPickListPatch = {
	pickListId: string
	check: true
	cancel: false // Unrelated, but serializer requires it.
}

export interface ReplenishmentDetail {
	parts: {
		onHand: number
		picking: number
		shipping: number
		incomingOrders: number
		outgoingOrders: number
		reorderPoint: number
		econ: number
		maxLevel: number
		orderAmount: number
		stock: true
		partId: string
		partNumber: string
		description: string
	}[]
	vendor: {
		id: number
		address: string | null
		city: string | null
		contactName: string | null
		name: string | null
		phone: string | null
		state: string | null
		vendorCode: string | null
		zipCode: string | null
	}
}

export type ReplenishmentPart = ReplenishmentDetail["parts"][number]

export type ReplenishmentDetailQueryParams = {
	vendor: string | null
	location: string | null
	partType: InventoryPartType | null
}

export interface ReplenishmentSubmissionPost {
	parts: {
		partId: string
		quantity: number
	}[]
	locationId: string
	vendorId: string
}

export type LocationPickListAssignmentsQueryParams = {
	startDate: string | null
}

export interface BillOfMaterial {
	id: string
	isActive: boolean
	items: {
		id: string
		description: string | null
		partDescription: string | null
		partId: string | null
		partNumber: string | null
		quantity: number | null
	}[]
	name: string
	partDescription: string | null
	partId: string | null
	partNumber: string | null
}

export interface BillOfMaterialQueryParams {
	search: string | null
	bomId: string | null
	quantity: number | null
}

export interface CreateBillOfMaterialPost {
	name: string
	partId: string // The resulting part
	items: {
		partId: string
		description: string
		quantity: number
	}[]
}

export type UpdateBillOfMaterialPatch = Partial<{
	name: string
	isActive: boolean
	items: {
		partId: string
		description: string
		quantity: number
	}[]
}>

export interface CompleteBillOfMaterialPost {
	bomId: string
	quantity: number
	fromBinId: string
	toBinId: string
}

export interface ManufacturedPart {
	id: string
	batchGroup: string
	isObsolete: boolean
	location: {
		id: string
		code: string
		description: string
	}
	packageSize: string
	part: {
		id: string
		partNumber: string
		description: string
	}
	productType: {
		id: string
		description: string
	}
	staticAdu: number | null
}

export interface CreateManufacturedPartPost {
	batchGroup: string
	obs: boolean
	location: string
	packageSize: string
	part: string
	productType: string
	staticAdu: number | null
}

export type UpdateManufacturedPartPatch = Partial<
	Omit<CreateManufacturedPartPost, "location" | "part">
>

export interface BinPart {
	binCode: string
	binId: string
	description: string
	partId: string
	partNumber: string
	quantity: number
}

export interface BinPartQueryParams {
	partId: string
	locationId: string
}

export interface ReplacePartPost {
	oldPart: string | number
	newPart: string | number
}

export interface ReplacementSuccess {
	inventoryTransactionsDone: number
	rdcPartsRemoved: number
	onlinePartsExchanged: number
	openOrdersAffected: number
	localPartsAffected: number
	pickListsAffected: number
	partGroupsAffected: number
	matrixGroupsAffected: number
}

export interface InventoryRequestListItem {
	id: string
	assignedTo: UserMinimal | null
	createdBy: UserMinimal
	createdDate: string
	description: string | null
	inventoryType: {
		id: number
		description: string
	}
	location: LocationMinimal
	status: {
		id: number
		description: string
	}
	submittedBy: UserMinimal | null
	submittedDate: string | null
}

export type InventoryRequestQueryParams = {
	territory: string | null
	status: string | null
	inventoryType: InventoryRequestTypeId | null
	search: string | null
}

export interface InventoryRequest {
	assignedTo: string | null
	countedInventory: number | null
	createdBy: string
	createdDate: string
	currentInventory: number | null
	inventoryType: string
	parts: {
		binInfo: {
			binCode: string
			countedQuantity: number
			currentQuantity: number
			difference: number
			id: string
		}[]
		costDifference: number
		countedQuantity: number
		currentQuantity: number
		difference: number
		partDescription: string
		partId: string
		partNumber: string
		partStandardCost: number
	}[]
	requestDescription: string | null
	requestId: string
	requestLocation: string
	requestLocationId: string
	status: string
	submittedBy: string | null
	submittedDate: string | null
}

export enum InventoryRequestTypeId {
	Physical = "1",
	Cycle = "2",
}

export enum InventoryRequestTypeDescription {
	Physical = "Physical",
	Cycle = "Cycle",
}

export enum InventoryRequestStatusDescription {
	Open = "Open",
	CountsSubmitted = "Counts Submitted",
	Completed = "Completed",
	Canceled = "Canceled",
}

export interface CreateInventoryRequestLinePost {
	partId: string
	requestId: string
	quantity: number
	binId: string
}

export interface UpdateInventoryRequestLinePatch {
	quantity: number
}

export type CreateInventoryRequestPost = {
	location: string
	description: string | null
} & (
	| {
			requestType: InventoryRequestTypeId.Physical
			binStartsWith?: never
			detailProductsOnly?: never
			chemicalProductsOnly?: never
			partNumberStartsWith?: never
			parts?: never
			assignedTo?: never
	  }
	| {
			requestType: InventoryRequestTypeId.Cycle
			assignedTo: string | null
			binStartsWith: string | null
			detailProductsOnly: boolean
			chemicalProductsOnly: boolean
			partNumberStartsWith: string | null
			parts: string[] | null
	  }
)

export type CancelInventoryRequestPatch = {
	id: string
	cancel: boolean
}

export interface PartInBin {
	binCode: string
	binId: number
	locationCode: string
	/**
	 * Dangerously huge integer
	 * @deprecated
	 */
	locationId: number
	qty: number
	text: string
	value: number
}
