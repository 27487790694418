import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useQueryClient } from "react-query"
import { useHistory, useParams } from "react-router-dom"

import {
	makeApiErrorMessage,
	PointOfSaleUrlPaths,
	useCarouselImage,
	useCarouselImages,
	useCreateCarouselImage,
	useUpdateCarouselImage,
} from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Button,
	Card,
	ConfirmationModal,
	ConfirmationModalConfig,
	Divider,
	GridContainer,
	GridItem,
	HeadingDivider,
	HeadingDividerProps,
	LoadingSpinner,
	Paragraph,
	RadioBooleanFormField,
	TextInputFormField,
	useChangeCallback,
	useScrollToTopOnMount,
	useToast,
} from "@ncs/web-legos"

import {
	CarouselImageForm,
	CarouselImageFormSchema,
	carouselImageFormDefaults,
	carouselImageFormPayload,
	getFormValuesFromImage,
} from "./carousel-utils"
import { CarouselImages } from "./CarouselImages"
import { CarouselImagesPreview } from "./CarouselImagesPreview"

const CarouselItem: FC = () => {
	useScrollToTopOnMount()
	const { makeSuccessToast, makeErrorToast } = useToast()
	const history = useHistory()
	const { id } = useParams<{ id?: string }>()
	const [isSaving, setIsSaving] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [image, imageLoading] = useCarouselImage(id)
	const [images] = useCarouselImages()
	const createImage = useCreateCarouselImage()
	const updateImage = useUpdateCarouselImage()
	const queryClient = useQueryClient()

	const form = useForm<CarouselImageForm>({
		resolver: zodResolver(CarouselImageFormSchema),
		defaultValues: carouselImageFormDefaults,
	})
	const { control, handleSubmit, reset } = form

	useChangeCallback(
		image,
		(newImage) => {
			reset(getFormValuesFromImage(newImage))
		},
		{ callOnSetup: true }
	)

	const backToList = () => {
		history.push("/carousel")
	}

	const headingDividerProps: HeadingDividerProps = {
		headingVariant: "h3",
		mt: 2,
		mb: 0.5,
	}

	const onSave = async (formData: CarouselImageForm) => {
		// Figure out what the current highest presentation order is and add 1.
		const backOfTheLine =
			images?.length ? Math.max(...images.map((c) => c.presentationOrder)) + 1 : 0

		try {
			setIsSaving(true)
			if (isEditing) {
				// If the images is going from inactive to active, then we don't really know where
				// to put it in the order, so just make it last.
				await updateImage({
					id: image.id,
					updates: carouselImageFormPayload(
						formData,
						!image.isActive && formData.isActive ?
							backOfTheLine
						:	image.presentationOrder
					),
				})
				makeSuccessToast("Carousel image updated")
			} else {
				// New images can start at the back.
				await createImage(carouselImageFormPayload(formData, backOfTheLine))
				makeSuccessToast("Carousel image created")
			}
			// Normally the query clearing doesn't need to be called from here, but since this page
			// is consuming the images endpoint and we also leave here right away after success,
			// we end up in a weird race condition where the image table on the list page does
			// not update as expected.
			await queryClient.invalidateQueries(PointOfSaleUrlPaths.PointOfSale)
			backToList()
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	if (imageLoading) {
		return (
			<Card>
				<LoadingSpinner />
			</Card>
		)
	}

	const isEditing = !!image

	return (
		<FormProvider {...form}>
			<Button icon="long-arrow-left" containerProps={{ mb: 3 }} onClick={backToList}>
				Back to list
			</Button>

			<Card
				heading={isEditing ? `Homepage Carousel: ${image.name}` : "New Carousel Item"}
				headingIcon="home"
				headingDetail={
					image?.modifiedOn ?
						`Last modified ${formatDateTime(image.modifiedOn)} by ${
							image.modifiedByName
						}`
					:	undefined
				}
			>
				<GridContainer columnGap={5} mdProps={{ columnGap: 2 }}>
					<GridItem sm={12} md={6}>
						<TextInputFormField
							control={control}
							name="name"
							label="Name (used internally only)"
							placeholder="Name..."
							emptyValueFallback=""
							maxLength={255}
						/>

						<HeadingDivider {...headingDividerProps}>Status</HeadingDivider>
						<RadioBooleanFormField
							control={control}
							name="isActive"
							yesText="Active (visible)"
							noText="Inactive (hidden)"
						/>

						<HeadingDivider {...headingDividerProps}>Carousel Images</HeadingDivider>
						<CarouselImages />

						<HeadingDivider {...headingDividerProps}>Destination URL</HeadingDivider>
						<TextInputFormField
							control={control}
							name="destinationUrl"
							label="Destination to visit when the user clicks on the carousel image (Optional)"
							maxLength={1024}
							placeholder="Destination URL..."
							emptyValueFallback=""
						/>

						<HeadingDivider {...headingDividerProps}>Text</HeadingDivider>
						<TextInputFormField
							control={control}
							name="text"
							label="Text to display on the image (Optional)"
							maxLength={255}
							placeholder="Text..."
							emptyValueFallback=""
						/>
					</GridItem>
					<GridItem sm={12} md={6}>
						<div css={stickyContainerCss}>
							<Paragraph bold mb={1}>
								Preview
							</Paragraph>
							<CarouselImagesPreview />
						</div>
					</GridItem>
				</GridContainer>

				<Divider fullCardBleed mt={3} mb={2} />
				<Button variant="primary-cta" onClick={handleSubmit(onSave)} isLoading={isSaving}>
					{isEditing ? "Save Changes" : "Create"}
				</Button>
				<Button variant="clear" onClick={backToList}>
					Cancel
				</Button>
			</Card>

			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</FormProvider>
	)
}

const stickyContainerCss = (theme: Theme) => css`
	position: sticky;
	top: 1rem;
	${theme.breakpoints.down("md")} {
		position: relative;
	}
`

export default CarouselItem
